import {
  RDCNavbar,
  RDCFooter,
  RDCEnergyConciergeHero,
  RDCOurTeamCarousel,
  RDCBackedBy,
  RDCFinishedScrollingForm,
} from "../components";
import { UserGroupIcon, ShieldCheckIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import mixpanel from "mixpanel-browser";
import AliProfile from "../images/team/AliSarilgan.png";
import OzgeProfile from "../images/team/OzgeIslegenWojdyla.png";
import ChristineProfile from "../images/team/ChristineManegan.png";
import TeganProfile from "../images/team/TeganFleishman.png";
import JessProfile from "../images/team/JessKerlin.png";

const PAGE_ID = "about-us";

// NOTE: the 'alt=""' specified on the images is intentional; See https://webaim.org/standards/wcag/checklist#sc1.1.1 for more info.
const TEAM_MEMBERS = [
  {
    name: "Ali Sarilgan",
    title: "CEO & Co-Founder",
    description:
      "Previously led corporate strategy for consumer technology companies and clean technology providers, and advised energy utilities. Graduated with MS and BS from Stanford",
    linkedin: "https://www.linkedin.com/in/alisarilgan",
    img: <img src={AliProfile} alt="" className="w-full h-full object-cover" />,
  },
  {
    name: "Ozge Islegen-Wojdlya",
    title: "CTO and Co-Founder",
    description:
      "Former Assistant Professor at Northwestern and Stanford PhD focused on energy markets. Previously led statistical interventions and testing as Senior Data Scientist at Instacart",
    linkedin: "https://www.linkedin.com/in/ozge-islegen-wojdyla/",
    img: <img src={OzgeProfile} alt="" className="w-full h-full object-cover" />,
  },
  {
    name: "Christine Manegan",
    title: "Founding Engineer",
    description:
      "Former Software and Data Engineer at Snapchat, and Software Engineer at Apple. Studied Computer Science at Stanford",
    linkedin: "https://www.linkedin.com/in/christine-astoria-manegan-6a4812159/",
    img: <img src={ChristineProfile} alt="" className="w-full h-full object-cover" />,
  },
  {
    name: "Tegan Fleishman",
    title: "Founding Engineer",
    description:
      "Former Software Engineer at AWS and Kindle. Studied Computer Science and Entrepreneurship at California Polytechnic State University, San Luis Obispo",
    linkedin: "https://www.linkedin.com/in/tegan-fleishman/",
    img: <img src={TeganProfile} alt="" className="w-full h-full object-cover" />,
  },
  {
    name: "Jess Kerlin",
    title: "Founding Machine Learning Engineer",
    description: (
      <div>
        Former Senior Machine Learning Manager at{" "}
        <a className="daisy-link" href="http://data.ai/" target="_blank" rel="noopener noreferrer">
          data.ai
        </a>
        , Postdoctoral researcher at UC Davis and Phd at the University of Birmingham, U.K.
      </div>
    ),
    linkedin: "https://www.linkedin.com/in/jesskerlin/",
    img: <img src={JessProfile} alt="" className="w-full h-full object-cover" />,
  },
];

/**
 * About Us Page Component
 */
export const RDCAboutUsPage: React.FC = () => {
  useEffect(() => {
    mixpanel.track("aboutUs");
  }, []);

  return (
    <>
      <Helmet>
        <title>EcoTrove | About Us</title>
        <meta
          name="description"
          content="EcoTrove is your energy concierge. We believe you deserve to use the power you need, without breaking the bank or the environment."
        />
        <meta
          name="keywords"
          content="affordable clean energy, accessible clean energy, Ali Sarilgan, Ozge Islegen-Wojdyla, clean energy subscription, lower energy bills, EcoTrove, green power, ecotrove team, Jess Kerlin, Tegan Fleishman, Christine Manegan"
        />
      </Helmet>
      <div id={PAGE_ID} className="tw-preflight snap-scroll-page">
        {/* Navbar */}
        <RDCNavbar contentId={PAGE_ID} />

        {/* Hero Section */}
        <div className="snap-start bg-white lg:pt-8 pb-16">
          <div className="px-content-mobile lg:px-content">
            <RDCEnergyConciergeHero />
          </div>
        </div>

        {/* "Our Values" Section */}
        <div className="snap-start bg-gradient-to-b from-secondary to-white py-16">
          <div className="px-content-mobile lg:px-content">
            <div className="text-left">
              <h2 className="text-black text-3xl lg:text-4xl text-left">Our Values</h2>
              <div className="max-w-5xl">
                <p className="text-neutral-content text-base md:text-md mt-4">
                  Whether you hear “Eco” and think of the economy or the environment, we believe your power
                  bills should be lower. You deserve to power your home or business without breaking the bank or the environment, and we built our AI Energy Champion for you.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-12 text-center lg:text-left ">
              {[
                {
                  icon: UserGroupIcon,
                  title: "People First",
                  description:
                    "We root every decision in the experiences of those that use our services, and prioritize helping them navigate an increasingly complex energy ecosystem over all other business considerations.",
                },
                {
                  icon: ShieldCheckIcon,
                  title: "Integrity",
                  description:
                    "We are committed to being reliable stewards of the trust placed in us by our customers and stakeholders. We earn this trust by respecting your data privacy and honoring our promise to lower your utility bills.",
                },
                {
                  icon: GlobeAltIcon,
                  title: "Impact Focused",
                  description:
                    "We prioritize bold and impactful ideas that can limit global carbon emissions and allow people to use the power they need.",
                },
              ].map(({ icon: Icon, title, description }) => (
                <div key={title} className="snap-start flex flex-col items-center lg:items-start">
                  <Icon className="w-12 h-12 text-black" />
                  <h3 className="text-black text-lg mt-4">{title}</h3>
                  <p className="text-neutral-content text-sm mt-2 max-w-md">{description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Our Team Section */}
        <div className="snap-start text-center mt-16 px-content-mobile lg:px-content pt-content-mobile lg:pt-content">
          <h2 className="text-black text-3xl lg:text-4xl">Our Team</h2>
        </div>
        <RDCOurTeamCarousel teamMembers={TEAM_MEMBERS} />

        {/* Hiring section */}
        <div className="snap-start flex flex-col items-center bg-neutral py-16 gap-y-4">
          <h2 className="text-3xl">We're hiring!</h2>
          <p className="text-neutral-content text-center">
            Browse open positions and reach out to
            <br />
            careers@ecotrove.com with questions.
          </p>
          <a
            className="daisy-btn daisy-btn-neutral border border-neutral-200 px-button"
            href="https://jobs.ashbyhq.com/pear/a64e2e65-fe58-4081-bec9-9ea4de290bca"
            target="_blank"
            rel="noopener noreferrer">
            Open positions
          </a>
        </div>

        <div className="snap-start text-center p-content-mobile lg:p-content mb-8">
          <RDCBackedBy />
        </div>

        {/* View height: finished scrolling & footer */}
        <div className="snap-start flex flex-col min-h-full w-full">
          <RDCFinishedScrollingForm />
          <div className="grow" />
          <RDCFooter />
        </div>
      </div>
    </>
  );
};
