import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { ACTION_LINKS, LOGIN_ROUTE, MAIN_MENU_LINKS_AUTH, MAIN_MENU_LINKS_UNAUTH } from "../../constants";
import { MouseEventHandler, useEffect, useState } from "react";
import { Utils } from "../../utils";
import { RDCEcoTroveLogoLink } from "./RDCEcoTroveLogoLink";
import { supabase } from "../../supabaseClient";
import { Link as EcoLink } from "../../types";
import { useAlertStore } from "../stores";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

/** Props to render a `RDCNavbar` with. */
export interface RDCNavbarProps {
  /**
   * The ID of the content that is rendered under the navbar. For pages that use snap scrolling,
   * this is needed to determine if the content has been scrolled and to style the navbar accordingly.
   */
  contentId?: string;
}

/**
 * Navigation bar component that should be rendered on every page.
 * @returns a React `FunctionComponent`.
 */
export const RDCNavbar: React.FC<RDCNavbarProps> = (props) => {
  const { contentId } = props;
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [logOutLoading, setLogOutLoading] = useState(false);
  const [name, setName] = useState<string>("");
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [hamburgerDropdownOpen, setHamburgerDropdownOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  // Links
  const [actionLink, setActionLink] = useState<EcoLink>();
  const { setErrorAlert } = useAlertStore();

  /* ---- EFFECTS ---- */

  // Check whether the user is logged in so that the correct auth button can be rendered
  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        // Comment this out for local testing
        const {
          data: { user },
        } = await supabase.auth.getUser();

        // Uncomment this for local testing
        // const user = { id: process.env.REACT_APP_TEST_USER_ID ?? "" };

        if (user) {
          setLoggedIn(true);
          const { data, error } = await supabase.from("user-profiles").select("name").eq("user_id", user.id).single();
          if (error || !data?.name) {
            throw new Error("an error occurred");
          }
          setName(data.name);
        }

        setActionLink(user ? ACTION_LINKS.authenticated : ACTION_LINKS.unauthenticated);
        // Add a slight delay to make the loading smoother
        setTimeout(() => setLoading(false), 100);
      } catch (error) {
        setErrorAlert("An error occurred while fetching user data. Please try again later.");
      }
    };
    checkLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Keep track of the window's scrollY so that styling can be applied on scroll
  useEffect(() => {
    const scrollElement = contentId ? document.getElementById(contentId) ?? window : window;
    const handleScroll = () => setScrollY("scrollY" in scrollElement ? scrollElement.scrollY : scrollElement.scrollTop);
    scrollElement.addEventListener("scroll", handleScroll);
    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, [contentId]);

  /* ---- HANDLERS ---- */

  /**
   * Handler for logout events.
   */
  const handleLogOut: MouseEventHandler = async () => {
    try {
      setLogOutLoading(true);
      await supabase.auth.signOut();
      localStorage.clear();
      // Intentional use of window.location instead of navigate() here
      // This forces the page to refresh even if log out is clicked on the home page
      window.location.href = "/";
    } catch (error) {
      setErrorAlert("An error occurred while attempting to log out. Please try again later.");
    }
  };

  /**
   * Handler for user dropdown menu click events.
   * @param e the mouse event
   */
  const handleUserDropdownClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setUserDropdownOpen((prev) => !prev);
  };

  /**
   * Handler for hamburger dropdown menu click events.
   * @param e the mouse event
   */
  const handleHamburgerDropdownClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setHamburgerDropdownOpen((prev) => !prev);
  };

  /* ---- HELPERS ---- */

  /**
   * Helper method that creates an array of link elements based on the screen size.
   */
  const getMenuLinks = (props: { isSmallScreen: boolean }): JSX.Element[] => {
    const { isSmallScreen } = props;
    const className = isSmallScreen ? "w-full" : "";
    const showFirst = loggedIn ? MAIN_MENU_LINKS_AUTH : MAIN_MENU_LINKS_UNAUTH;
    const showSecond = loggedIn ? MAIN_MENU_LINKS_UNAUTH : MAIN_MENU_LINKS_AUTH;
    const links = Utils.getListElementsFromLinks(showFirst, { className });
    // Add additional elements to menu on smaller screens
    if (isSmallScreen) {
      if (loggedIn)
        links.push(
          ...Utils.getListElementsFromLinks(showSecond, {
            className,
            keyOffset: showFirst.length,
          })
        );
      links.push(getAuthElement({ small: true }));
    }
    return links;
  };

  /**
   * Helper method that creates an authElement based on the screen size.
   */
  const getAuthElement = (props: { small?: boolean }): JSX.Element => {
    const { small } = props;
    const className = `daisy-btn daisy-btn-secondary ${small ? "daisy-btn-sm w-full" : ""}`;
    return loggedIn ? (
      <button key="logout-button" className={className} onClick={handleLogOut} disabled={logOutLoading}>
        Log Out
      </button>
    ) : (
      <Link key="logout-link" className={className} to={LOGIN_ROUTE}>
        Log In
      </Link>
    );
  };

  return (
    <header
      id="ecotrove-navbar"
      className={`fixed w-full top-0 left-0 z-40 flex bg-base-100 ${
        scrollY ? "border-b border-neutral-200" : ""
      } px-content-mobile lg:px-content py-3`}>
      {/* Logo */}
      <span className="-ml-2">
        <RDCEcoTroveLogoLink />
      </span>
      {/* Center menu (if larger screen) */}
      <div className="grow flex justify-center">
        {loading && <div className="daisy-skeleton h-12 w-96 hidden lg:flex" />}
        {!loading && (
          <ul className="daisy-menu daisy-menu-md daisy-menu-horizontal hidden lg:flex">
            {getMenuLinks({ isSmallScreen: false })}
          </ul>
        )}
      </div>
      {/* Action menu */}
      {loading && <div className="daisy-skeleton h-12 w-64" />}
      {!loading && actionLink && (
        <div className="flex-none flex">
          {/* Action link */}
          <Link className="daisy-btn daisy-btn-primary text-md" to={actionLink.url}>
            {"label" in actionLink ? actionLink.label : actionLink.icon}
          </Link>
          {/* Auth element (if larger screen) */}
          <span className="hidden lg:flex ml-default">
            {loggedIn ? (
              <details className="daisy-dropdown daisy-dropdown-end hidden lg:flex" open={userDropdownOpen}>
                <summary
                  className={`flex items-center rounded-3xl border-default gap-default ml-default px-1.5 pr-button cursor-pointer ${
                    userDropdownOpen ? "bg-secondary" : ""
                  } hover:bg-secondary ease-in-out duration-200`}
                  aria-label="open user menu"
                  onClick={handleUserDropdownClick}>
                  <div className="flex place-content-center daisy-mask daisy-mask-circle p-3.5 bg-neutral select-none">
                    {name[0].toUpperCase()}
                  </div>
                  <div className="text-lg select-none">{name}</div>
                  {userDropdownOpen ? (
                    <ChevronUpIcon className="hero-icon size-4" />
                  ) : (
                    <ChevronDownIcon className="hero-icon size-4" />
                  )}
                </summary>
                <ul className="daisy-dropdown-content daisy-menu items-start bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                  {Utils.getListElementsFromLinks(MAIN_MENU_LINKS_UNAUTH, {
                    className: "w-full",
                    textStyle: "text-base-mobile",
                  })}
                  {getAuthElement({ small: true })}
                </ul>
              </details>
            ) : (
              getAuthElement({ small: false })
            )}
          </span>
          {/* Dropdown menu (if smaller screen) */}
          <details className="daisy-dropdown daisy-dropdown-end lg:hidden" open={hamburgerDropdownOpen}>
            <summary
              id="hamburger-swap"
              className={`daisy-btn daisy-btn-ghost ml-default -mr-2 daisy-swap daisy-swap-rotate ${
                hamburgerDropdownOpen ? "daisy-swap-active" : ""
              }`}
              aria-label="open dropdown menu"
              onClick={handleHamburgerDropdownClick}>
              <input type="checkbox" aria-labelledby="hamburger-swap" />
              <XMarkIcon className="hero-icon daisy-swap-on" />
              <Bars3Icon className="hero-icon daisy-swap-off" />
              {/* {hamburgerDropdownOpen ? <XMarkIcon className="hero-icon" /> : <Bars3Icon className="hero-icon" />} */}
            </summary>
            <ul className="daisy-dropdown-content daisy-menu items-start bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
              {getMenuLinks({ isSmallScreen: true })}
            </ul>
          </details>
        </div>
      )}
    </header>
  );
};
