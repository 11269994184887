import { useNavigate, useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { SavingsType, UtilityProvider } from "../../types";
import { Utils } from "../../utils";
import {
  CheckAvailabilityHeroFieldValues,
  RDCCheckAvailabilityForm,
  RDCCheckBoxInput,
  RDCFooter,
  RDCIFrameModal,
  RDCNavbar,
  RDCTextWithButtonInput,
  RDCValidationError,
} from "../components";
import {
  SAVINGS_TYPE_SCHEMA,
  EMAIL_REGEX,
  ZIP_CODE_REGEX,
  UTILITIES_SERVED,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  PGE_OAUTH_REDIRECT_ROUTE,
  STATUS_403_ROUTE,
} from "../../constants";
import PGELogo from "../images/logos/PGELogo.svg";
import { MouseEvent, useEffect, useState } from "react";
import { supabase } from "../../supabaseClient";
import { z } from "zod";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet";
import { useAlertStore } from "../stores";

interface GetQuoteFormValues {
  savingsType: SavingsType;
  email: string;
  privacyPolicy: boolean;
  termsOfService: boolean;
}

interface JoinEmailListValues {
  email: string;
}

const STAGE_SCHEMA = z.enum(["zipCode", "provider", "pilotProgram"]);

const GET_QUOTE_VALIDATIONS: Record<keyof GetQuoteFormValues, Object> = {
  savingsType: { required: true },
  email: { required: true, pattern: EMAIL_REGEX },
  privacyPolicy: { required: true },
  termsOfService: { required: true },
};

const PROVIDER_CONFIG: Record<
  UtilityProvider,
  { displayName: string; displayUrl: string; url: { location: string; params: Record<string, string> }; logo: string }
> = {
  pge: {
    displayName: "PG&E",
    displayUrl: "pge.com",
    url: {
      location: "https://sharemydata.pge.com/myAuthorization",
      params: {
        client_id: "8fc92047bb9b446d9ed5de3de21e8600",
        response_type: "code",
      },
    },
    logo: PGELogo,
  },
};

/**
 * Page that prompts a user to enter their utility email to link to their account and retrieve a quote.
 * @returns a React component.
 */
export const RDCGetQuotePage: React.FC = () => {
  const navigate = useNavigate();
  const { setSuccessAlert, setErrorAlert } = useAlertStore();
  const [interestUsersRecordId, setInterestUsersRecordId] = useState("");

  // Query param state
  const [searchParams, setSearchParams] = useSearchParams();
  const [provider, setProvider] = useState<UtilityProvider | undefined>();
  const [stage, setStage] = useState<z.infer<typeof STAGE_SCHEMA>>("zipCode");

  // Check boxes state
  const [privacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);

  // Form state
  const [joinPilotFormDisabled, setJoinPilotFormDisabled] = useState(false);
  const getQuoteForm = useForm<GetQuoteFormValues>({
    defaultValues: {
      savingsType: SAVINGS_TYPE_SCHEMA.safeParse(searchParams.get("savingsType")).data ?? "household",
    },
  });
  const joinPilotForm = useForm<JoinEmailListValues>();

  /* ---- HANDLERS ---- */

  /**
   * Submit handler for the check ZIP code form (to check if user's ZIP code is supported).
   * @param param0 the form data
   */
  const handleSubmitCheckZipCode: SubmitHandler<CheckAvailabilityHeroFieldValues> = async ({ zipCode }) => {
    mixpanel.track("Check ZIP code for availability");
    try {
      const { data: interestData, error: interestError } = await supabase
        .from("interest-users")
        .insert({ zipcode: zipCode })
        .select()
        .single();
      if (interestError) throw interestError;
      setInterestUsersRecordId(interestData.id);
      // Check if ZIP code is currently served
      const { data: zipCodeData, error: zipError } = await supabase
        .from("pge_zipcodes_new")
        .select()
        .eq("zipcode", zipCode)
        .in("utility_name", UTILITIES_SERVED);
      // If an error was returned, throw it. This will be handled and displayed as an error message below
      if (zipError) throw zipError;
      // Navigate to get quote page
      if (zipCodeData && zipCodeData.length > 0) {
        setProvider("pge");
        searchParams.set("zipCode", zipCode);
        searchParams.set("stage", "provider");
      } else {
        searchParams.delete("zipCode");
        searchParams.set("stage", "pilotProgram");
      }
      setSearchParams(searchParams, { replace: true });
    } catch (error) {
      // Catch errors and display an error alert
      setErrorAlert("An error occurred while checking ZIP code. Please try again later.");
      window.scrollTo(0, 0);
      return;
    }
  };

  /**
   * Submit handler for the get quote form (if user's ZIP code is supported).
   * @param param0 the form data
   */
  const handleSubmitGetQuote: SubmitHandler<GetQuoteFormValues> = async ({ savingsType, email }) => {
    // If the user is already paying, they should not be able to sign up for a new quote
    if (!(await Utils.canGetQuote(email))) {
      navigate(STATUS_403_ROUTE);
      return;
    }

    mixpanel.track("Log in to utility provider to get quote");
    const { error } = await supabase
      .from("terms-and-conditions")
      .insert([{ email, privacy_checked: true, terms_checked: true }])
      .select();

    const { error: errorTerms } = await supabase
      .from("user-profiles")
      .update({ terms_complete: true })
      .eq("email", email);

    if (error || errorTerms) {
      console.error("error uploading row for terms and conditions completion for: ", email);
    }

    const { location, params } = PROVIDER_CONFIG[provider!].url;
    const url = new URL(location);
    const testing = process.env.REACT_APP_WEBSITE_URL === "https://ecotrove.com" ? "0" : "1";
    const userType = savingsType === "household" ? "0" : "1";
    const state = testing + userType + email;
    url.search = new URLSearchParams({
      ...params,
      state,
      redirect_uri: `https://ecotrove.com${PGE_OAUTH_REDIRECT_ROUTE}`,
    }).toString();
    window.location.href = url.href;
  };

  /**
   * Submit handler for the join pilot program form (if user's ZIP code is not currently supported by our full service).
   * @param param0 the form data
   */
  const handleSubmitJoinPilot: SubmitHandler<JoinEmailListValues> = async ({ email }) => {
    mixpanel.track("Join pilot program");
    try {
      setJoinPilotFormDisabled(true);
      const { error } = await supabase
        .from("interest-users")
        .update({ email, join_pilot: true })
        .eq("id", interestUsersRecordId);
      if (error) throw error;
      setSuccessAlert("We have processed your request. Please check your inbox!");
    } catch (error) {
      setErrorAlert("An error occurred while submitting your request. Please try again later.");
    }
  };

  /* ---- EFFECTS ---- */

  // Track page visits
  useEffect(() => {
    mixpanel.track("getQuote");
  }, []);

  // Process query params
  useEffect(() => {
    const zipCodeParam = searchParams.get("zipCode") ?? "";
    const stageParam = STAGE_SCHEMA.safeParse(searchParams.get("stage"));
    // If ZIP code param is  set, submit the check ZIP code form
    // This case is typically a param set by another page
    if (ZIP_CODE_REGEX.test(zipCodeParam)) {
      handleSubmitCheckZipCode({ zipCode: zipCodeParam });
    }
    // If stage param is set, update the stage state
    if (stageParam.data) {
      setStage(stageParam.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>EcoTrove | Get Quote</title>
        <meta
          name="description"
          content="Get a personalized quote to replace your utility bills with cheaper, predictable, clean energy."
        />
        <meta
          name="keywords"
          content="utility account, EcoTrove quote, PG&E account, replace your utility bills, save on your utility bills, fixed-price energy, clean energy subscription, lower energy bills, EcoTrove, green power"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Get a Quote - EcoTrove" />
        <meta
          property="og:description"
          content="Get a personalized quote to replace your utility bills with cheaper, predictable, clean energy. Start saving today!"
        />
        <meta property="og:image" content="https://i.postimg.cc/FKQJMKhq/Screenshot-2024-12-02-at-8-52-36-AM.png" />
        <meta property="og:url" content="https://ecotrove.com/get-quote" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Get a Quote - EcoTrove" />
        <meta
          name="twitter:description"
          content="Get a personalized quote to replace your utility bills with cheaper, predictable, clean energy. Start saving today!"
        />
        <meta name="twitter:image" content="https://i.postimg.cc/FKQJMKhq/Screenshot-2024-12-02-at-8-52-36-AM.png" />
      </Helmet>
      <div className="tw-preflight flex flex-col min-h-dvh">
        <RDCNavbar />

        <div className="grow grid grid-cols-1 lg:grid-cols-2 gap-8 place-items-center bg-secondary lg:px-content pt-24 pb-8">
          {/* Left column */}
          <div className="col-span-1 flex flex-col place-items-center w-full rounded-3xl bg-base-100 py-12 px-content-mobile lg:px-content">
            {/* Check availability */}
            {stage === "zipCode" && [
              <h1 key="title" className="text-xl lg:text-2xl mb-10">
                Check your ZIP code's availability
              </h1>,
              <RDCCheckAvailabilityForm
                data-tests={{ zipCode: "zipcode-quote" }}
                key="form"
                onSubmit={handleSubmitCheckZipCode}
              />,
            ]}

            {/* Pilot program */}
            {stage === "pilotProgram" && (
              <>
                <h1 className="text-xl lg:text-2xl">We are running a pilot in your area</h1>
                <p className="my-8">
                  Our full service is still a few months away, but you are welcome to join our pilot program free of
                  commitment. Enter your email and receive information on linking your utility account with EcoTrove and
                  joining our pilot.
                </p>
                <form
                  className="w-full"
                  onSubmit={joinPilotForm.handleSubmit((data) =>
                    handleSubmitJoinPilot({ ...data, email: data.email.toLowerCase() })
                  )}>
                  {/* Email */}
                  <div className="flex flex-col space-y-4">
                    <label htmlFor="email">Email</label>
                    <div className="flex w-full justify-between items-center gap-x-4">
                      <RDCTextWithButtonInput
                        data-test="email-pilot"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        invalid={Boolean(joinPilotForm.formState.errors.email)}
                        register={joinPilotForm.register}
                        validations={{
                          required: true,
                          validate: { matches: (value) => EMAIL_REGEX.test(value) || "Please enter a valid email" },
                        }}
                        buttonText="Join Pilot"
                        buttonType="primary"
                        disabled={joinPilotFormDisabled}
                      />
                    </div>
                    <RDCValidationError error={joinPilotForm.formState.errors.email?.message ?? ""} />
                  </div>
                </form>
              </>
            )}

            {/* Get quote form */}
            {stage === "provider" &&
              provider && [
                <div key="title" className="flex gap-2 items-center mb-10">
                  <img className="w-12" src={PROVIDER_CONFIG[provider].logo} alt="utility company branding" />
                  <h1 className="text-xl lg:text-2xl">Connect your utility account and check savings</h1>
                </div>,
                <form
                  key="form"
                  className="w-full space-y-6"
                  onSubmit={getQuoteForm.handleSubmit((data) =>
                    handleSubmitGetQuote({ ...data, email: data.email.toLowerCase() })
                  )}>
                  {/* savingsType input */}
                  <fieldset className="flex justify-between space-y-4">
                    <legend>Are you a household or business?</legend>
                    {["household", "business"].map((savingsType) => (
                      <div className="flex items-center gap-x-default px-4" key={savingsType}>
                        <input
                          className="daisy-radio daisy-radio-primary"
                          type="radio"
                          id={savingsType}
                          value={savingsType}
                          {...getQuoteForm.register("savingsType", GET_QUOTE_VALIDATIONS.savingsType)}
                        />
                        <label htmlFor={savingsType}>{Utils.capitalize(savingsType)}</label>
                      </div>
                    ))}
                  </fieldset>
                  {/* Email */}
                  <div className="flex flex-col space-y-4">
                    <label htmlFor="email">Email</label>
                    <div className="flex w-full justify-between items-center gap-x-4">
                      <RDCTextWithButtonInput
                        data-test="email-quote"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        invalid={Boolean(getQuoteForm.formState.errors.email)}
                        register={getQuoteForm.register}
                        validations={GET_QUOTE_VALIDATIONS.email}
                        buttonText={`Go to ${PROVIDER_CONFIG[provider].displayUrl}`}
                        buttonType="primary"
                      />
                    </div>
                    {getQuoteForm.formState.errors.email ? (
                      <p className="daisy-label daisy-label-text text-error mt-2" role="alert">
                        Please enter a valid email
                      </p>
                    ) : (
                      <div className="h-9" />
                    )}
                    <p className="text-neutral-content">
                      Enter your email and connect your {PROVIDER_CONFIG[provider].displayName} account with EcoTrove.
                      You will receive a customized quote to replace {PROVIDER_CONFIG[provider].displayName} bills and a
                      free energy efficiency report.
                    </p>
                  </div>
                  <div>
                    {/* Privacy policy */}
                    <RDCCheckBoxInput
                      name="privacyPolicy"
                      invalid={Boolean(getQuoteForm.formState.errors.privacyPolicy)}
                      register={getQuoteForm.register}
                      validations={GET_QUOTE_VALIDATIONS.privacyPolicy}
                      checkBoxType="primary"
                      checkBoxText={
                        <p className="text-xs lg:text-sm">
                          I have reviewed and I agree to EcoTrove's{" "}
                          <button
                            className="daisy-btn daisy-btn-link px-0 text-xs lg:text-sm"
                            onClick={(e: MouseEvent) => {
                              e.preventDefault();
                              setPrivacyModalOpen(true);
                            }}>
                            privacy policy
                          </button>
                          .
                        </p>
                      }
                    />
                    <RDCIFrameModal
                      id={"privacy-modal"}
                      open={privacyModalOpen}
                      src={`${PRIVACY_POLICY_ROUTE}?iframe=true`} // Pass query param here
                      title="Privacy Policy"
                      onClose={() => setPrivacyModalOpen(false)}
                    />
                    {/* Terms of service */}
                    <RDCCheckBoxInput
                      name="termsOfService"
                      invalid={Boolean(getQuoteForm.formState.errors.termsOfService)}
                      register={getQuoteForm.register}
                      validations={GET_QUOTE_VALIDATIONS.termsOfService}
                      checkBoxType="primary"
                      checkBoxText={
                        <p className="text-xs lg:text-sm">
                          I have reviewed and I agree to EcoTrove's{" "}
                          <button
                            className="daisy-btn daisy-btn-link px-0 text-xs lg:text-sm"
                            onClick={(e: MouseEvent) => {
                              e.preventDefault();
                              setTermsModalOpen(true);
                            }}>
                            terms of service
                          </button>
                          .
                        </p>
                      }
                    />
                    <RDCIFrameModal
                      id={"terms-modal"}
                      open={termsModalOpen}
                      src={`${TERMS_OF_SERVICE_ROUTE}?iframe=true`}
                      title="Terms of Service"
                      onClose={() => setTermsModalOpen(false)}
                    />
                  </div>
                </form>,
              ]}
          </div>

          {/* Right column */}
          <div className="flex flex-col items-center text-center gap-y-8">
            <p className="text-xl leading-none">
              We saved a customer in your area
              <br />
              $250+ last year.
            </p>
            <div className="rounded-3xl w-3/4 border border-primary border-opacity-10 p-8 space-y-5">
              <p className="font-semibold">Jack (Saving $200+)</p>
              <p className="text-primary text-center lg:text-start">
                "My EcoTrove plan is about 7% cheaper than what my
                PG&E bills would have been - and it's the same price every month."
              </p>
            </div>
          </div>
        </div>
        <RDCFooter showExplainer />
      </div>
    </>
  );
};
