import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { handleStartDocusign } from "../../startDocusign";
import { supabase } from "../../supabaseClient";
import { constructFullApiURL } from "../../constructFullApiUrl";
import { useAlertStore, useReferrerStore } from "../stores";
import mixpanel from "mixpanel-browser";

/**
 * Page for routing the user to DocuSign.
 * Displays a message while loading DocuSign.
 */
export const RDCLoadDocusignPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const sessionId = queryParams.get("session_id") || "";
  const { referrer } = useReferrerStore();
  const { setErrorAlert } = useAlertStore();

  useEffect(() => {
    mixpanel.track("loadDocusign");
  }, []);

  const confirmReferral = async (referrerEmail: string, referredEmail: string) => {
    const { data, error } = await supabase
      .from("redeemed-referrals")
      .insert({ referrer_email: referrerEmail, referred_email: referredEmail })
      .select();

    const { data: userData, error: userError } = await supabase
      .from("user-profiles")
      .update({ signup_via_referral: true })
      .eq("email", email)
      .select();

    console.log(data);
    console.log(userData);

    if (error || userError) {
      console.error("Error updating referral data: ", error);
    }
  };

  const handleStripeId = async (sessionId: string, email: string) => {
    const apiURL = constructFullApiURL(`/fetch-stripe-customer-id`);

    // Fetch the customerId from the backend endpoint
    const response = await fetch(apiURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // Include any necessary data in the request body
        sessionId: sessionId,
      }),
    });

    if (!response.ok) {
      console.error("Failed to fetch customer ID:", response.statusText);
      return;
    }

    const data = await response.json();
    const customerId = data.customerId;

    if (customerId) {
      const { error: customerUpdateError } = await supabase
        .from("user-profiles")
        .update({ stripe_customer_id: customerId })
        .eq("email", email);

      if (customerUpdateError) {
        console.log("Error updating customer ID of user: ", customerUpdateError);
      }
    }
  };

  const uploadUserBills = async (email: string) => {
    try {
      // Fetch user profile data
      const { data: userProfileData, error: userProfileError } = await supabase
        .from("user-profiles")
        .select("name, monthly_subscription")
        .eq("email", email);

      if (userProfileError || !userProfileData || userProfileData.length === 0) {
        console.error("Error fetching user profile: ", userProfileError);
        return;
      }

      const { name, monthly_subscription } = userProfileData[0];

      // Generate 13 rows starting with last month
      const rows = Array.from({ length: 13 }, (_, i) => {
        const month = new Date();
        month.setMonth(month.getUTCMonth() - 1 + i); // Start with last month and increment by i months
        const formattedMonth = month.toISOString().slice(0, 10); // Format as YYYY-MM-DD

        return {
          email,
          name,
          subscription_fee: monthly_subscription,
          month: formattedMonth,
        };
      });

      // Insert rows into user-bills
      const { data: userBillsData, error: userBillsError } = await supabase.from("user-bills").insert(rows);

      if (userBillsError) {
        console.error("Error inserting user bills:", userBillsError);
      } else {
        console.log("Inserted user bills successfully:", userBillsData);
      }
    } catch (error) {
      console.error("Unexpected error: ", error);
    }
  };

  useEffect(() => {
    if (!email) {
      setErrorAlert("Error: Session Expired.");
      return;
    }
    if (referrer !== undefined) {
      confirmReferral(referrer.email, email);
    }
    uploadUserBills(email);
    handleStripeId(sessionId, email);
    handleStartDocusign(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <div className="tw-preflight bg-base-100 min-h-screen flex flex-col">
      {/* Main Content */}
      <div className="flex flex-1 items-center justify-center text-center">
        <div className="daisy-card daisy-shadow-lg daisy-max-w-lg daisy-p-6">
          <div className="daisy-card-body">
            <h1 className="text-2xl font-bold text-black mb-4">
              Payment Successful. We are routing you to DocuSign...
            </h1>
            <p className="daisy-text-lg daisy-text-neutral-content daisy-mt-4">
              Please sign our subscriber agreement to authorize EcoTrove to pay your bills with PG&E.
            </p>
            <p className="daisy-text-lg daisy-text-neutral-content daisy-mt-2">Please do not exit this screen.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
