import { PropsWithChildren } from "react";
import GrowthIcon from "../images/icons/GrowthIcon.svg";
import WindPowerIcon from "../images/icons/WindPowerIcon.svg";
import { RDCEcoTroveLogoLink } from "./RDCEcoTroveLogoLink";

/**
 * Component that renders child content inside a centered card, with custom EcoTrove artwork in the background.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCCardWithBackground: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <>
      {/* Background images */}
      <div className="fixed flex flex-col lg:flex-row justify-between bg-accent h-lvh w-lvw">
        <img className="hidden lg:flex h-full" src={GrowthIcon} alt="plant with leaves" />
        <img className="h-full" src={WindPowerIcon} alt="wind turbine" />
      </div>
      {/* EcoTrove logo + card */}
      <div className="flex place-content-center w-full px-content-mobile lg:px-content py-content-mobile lg:py-8">
        <div className="flex flex-col items-center gap-4 z-10 w-full">
          <header>
            <RDCEcoTroveLogoLink variant="primary" />
          </header>
          <div className="bg-base-100 rounded-3xl p-6 space-y-8 w-full max-w-[720px]">{children}</div>
        </div>
      </div>
    </>
  );
};
