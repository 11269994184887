import { useParams } from "react-router-dom";
import { RDCStatusCodePage } from "./RDCStatusCodePage";
import { RDCActionCard, RDCActionCardProps, RDCCardWithBackground, RDCContactUsModal } from "../components";
import { CALENDLY_URL_ALI, TYPEFORM_CONNECT_UTILITY } from "../../constants";
import { CalendarDaysIcon, ShareIcon } from "@heroicons/react/24/outline";
import mixpanel from "mixpanel-browser";
import { Utils } from "../../utils";

const CONTACT_US_MODAL_ID_SPECIAL_QUOTE = "contact-us-modal-special-quote";

type SpecialCustomer = "solar" | "business";

/**
 * Page that displays a quote for a special-case customer (eg. solar).
 * @returns a React component.
 */
export const RDCSpecialQuotePage: React.FC = () => {
  const { type } = useParams();

  const CONFIG: Record<SpecialCustomer, { blurb: string; cta: RDCActionCardProps }> = {
    // Solar config
    solar: {
      blurb:
        "We have detected that you are a solar customer and we need more information to provide you with a guaranteed savings quote.",
      cta: {
        title: "Sharing Your Information",
        subTitle: "Fill out the linked utility authorization form to receive a custom quote within 24 hours.",
        body: (
          <div className="flex flex-col w-full items-center">
            <ShareIcon className="hero-icon size-32 text-neutral-content self-center" />
          </div>
        ),
        action: {
          type: "link",
          text: "Open Form",
          href: TYPEFORM_CONNECT_UTILITY,
          onClick: () => mixpanel.track(`open Type Form to connect utility manually from ${type} quote`),
        },
      },
    },
    // Business config
    business: {
      blurb:
        "Our business partners pay the same flat fee for power each month and save $1,500 per location. We will email your quote within 24 hours.",
      cta: {
        title: "You will get a quote within 24 hours",
        subTitle: "We are analyzing you energy usage and will be in touch soon!",
        body: <p className="grow flex w-full justify-center items-center">Questions?</p>,
        action: {
          type: "handler",
          text: "Contact Us",
          // No need to track this via mixpanel; the contact us modal component already tracks submissions
          onClick: () => Utils.openDialogModal(CONTACT_US_MODAL_ID_SPECIAL_QUOTE),
        },
      },
    },
  };

  if (type !== "solar" && type !== "business") {
    return <RDCStatusCodePage code={404} />;
  }
  const { blurb, cta } = CONFIG[type];

  return (
    <div className="tw-preflight">
      <RDCContactUsModal modalId={CONTACT_US_MODAL_ID_SPECIAL_QUOTE} />
      <RDCCardWithBackground>
        {/* Header */}
        <div className="flex flex-col items-center gap-default">
          <h1 className="text-lg">Thanks for trying EcoTrove!</h1>
          <h1 className="text-4xl">Predictable Energy</h1>
        </div>
        {/* Blurb */}
        <p className="text-center text-neutral-content">{blurb}</p>
        {/* Action cards */}
        <div className="flex flex-col items-center justify-between gap-default w-full">
          {type === "solar" && <p className="text-lg">Please help us by either:</p>}
          <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-5">
            <RDCActionCard {...cta} />
            <RDCActionCard
              title={type === "solar" ? "Scheduling a Call" : "Need a Consultation?"}
              subTitle="Schedule a free consultation call with one of our energy experts."
              body={
                <div className="flex flex-col w-full items-center">
                  <CalendarDaysIcon className="hero-icon size-32 text-neutral-content self-center" />
                </div>
              }
              action={{
                type: "link",
                text: "Schedule Call",
                href: CALENDLY_URL_ALI,
                onClick: () => mixpanel.track(`click schedule call on ${type} show quote page`),
              }}
            />
          </div>
        </div>
      </RDCCardWithBackground>
    </div>
  );
};
