import { SubmitHandler, useForm } from "react-hook-form";
import { RDCCaptcha, RDCCardWithBackground, RDCConfirmPasswordsInput, RDCValidationError } from "../components";
import {
  EMAIL_REGEX,
  GET_QUOTE_ROUTE,
  LOGIN_ROUTE,
  FULL_NAME_REGEX,
  USER_DASHBOARD_ROUTE,
  CONFIRM_EMAIL_ROUTE,
} from "../../constants";
import { EnvelopeIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import mixpanel from "mixpanel-browser";
import { useAlertStore } from "../stores";
import { Helmet } from "react-helmet";

interface CreateAccountForm {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

/**
 * Form page that allows a user to create an account.
 * @returns a React component.
 */
export const RDCCreateAccountPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setErrorAlert } = useAlertStore();
  const [captchaToken, setCaptchaToken] = useState<string>("");
  const [captchaInvalid, setCaptchaInvalid] = useState<boolean>(false);
  const [captchaKey, setCaptchaKey] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const createAccount = useForm<CreateAccountForm>();
  const { errors } = createAccount.formState;

  useEffect(() => {
    mixpanel.track("createAccount");
  }, []);

  useEffect(() => {
    checkLoggedIn();
    checkUserProfile();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Helper method that checks if a user is already logged in, and redirects them if so.
   */
  const checkLoggedIn = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (user) navigate(USER_DASHBOARD_ROUTE);
  };

  /**
   * Helper method that checks if an email is provided as a param, and prepopulates fields if so.
   */
  const checkUserProfile = async () => {
    try {
      const email = searchParams.get("email");
      if (email) {
        createAccount.setValue("email", email);
        const { href } = window.location;
        const { data, error } = await supabase.from("user-profiles").select().eq("email", email);
        if (error) throw error;
        if (!data.length || !data[0]?.name) return;
        // If user found, update the DB with the URL and populate the form with their name
        await supabase
          .from("user-profiles")
          .update({
            create_account_url: href,
          })
          .eq("email", email);
        createAccount.setValue("name", data[0].name);
      }
    } catch (error) {
      setErrorAlert("An error occurred while fetching user profile. Please try again later.");
    }
  };

  /**
   * Helper method that checks if an email is already in the user profiles table.
   * @param email the email to check
   * @returns whether the user is in the table.
   */
  const emailInUserProfiles = async (email: string): Promise<boolean> => {
    try {
      const { data, error } = await supabase.from("user-profiles").select().eq("email", email);
      if (error) throw error;
      return Boolean(data.length);
    } catch (error) {
      setErrorAlert("An error occurred while checking user profiles. Please try again later.");
      return false;
    }
  };

  /**
   * Handler for create account events.
   * @param data the data to create the account with.
   */
  const handleCreateAccount: SubmitHandler<CreateAccountForm> = async ({ name, email, password }) => {
    setLoading(true);
    try {
      // Check captcha
      if (!captchaToken) {
        setCaptchaInvalid(true);
        return;
      }
      setCaptchaInvalid(false);

      // Check if email is even in supabase user profiles; if not, we take the user through get quote flow
      const emailExists = await emailInUserProfiles(email);
      if (!emailExists) {
        // Safe cast: we know this element to be a dialog element
        (document.getElementById("get-quote-modal") as HTMLDialogElement).showModal();
        return;
      }

      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: "https://ecotrove.com/login",
          captchaToken,
        },
      });

      if (error) {
        if (error.code === "23505") {
          setErrorAlert("An account with this email already exists. Please log in instead.");
          return;
        }
        throw error;
      }

      const user = data?.user;
      const id = user?.id;
      if (user?.identities === undefined || !user?.identities.length) {
        setErrorAlert("An account with this email already exists. Please log in instead.");
        return;
      }

      // Update user profiles, service accounts, and all associated tables with user_id
      await Promise.all([
        supabase
          .from("user-profiles")
          .update({
            user_id: id,
            name,
            active_account: true,
          })
          .eq("email", email),

        supabase
          .from("service-accounts")
          .update({
            user_id: id,
          })
          .eq("email", email),

        supabase
          .from("terms-and-conditions")
          .update({
            user_id: id,
          })
          .eq("email", email),

        supabase
          .from("user-bills")
          .update({
            user_id: id,
          })
          .eq("email", email),

        supabase
          .from("savings-claimed")
          .update({
            user_id: id,
          })
          .eq("email", email),

        supabase
          .from("user-profiles")
          .update({
            email: email,
          })
          .eq("email", email),

        supabase
          .from("service-accounts")
          .update({
            email: email,
          })
          .eq("email", email),

        supabase
          .from("terms-and-conditions")
          .update({
            email: email,
          })
          .eq("email", email),
      ]);

      // Redirect to the confirm-email page
      navigate(`${CONFIRM_EMAIL_ROUTE}/${encodeURIComponent(email)}`);
    } catch (error) {
      setErrorAlert("An error occurred while attempting to create account. Please try again later.");
      return;
    } finally {
      // Increment captchaKey to force the captcha token to refresh
      setCaptchaKey((prev) => prev + 1);
      setLoading(false);
    }
  };

  /**
   * Handler for get quote button events.
   * @param e the button event
   */
  const handleGetQuote: MouseEventHandler = (e) => {
    e.preventDefault();
    navigate(GET_QUOTE_ROUTE);
  };

  return (
    <>
      <Helmet>
        <title>EcoTrove | Create Account</title>
        <meta
          name="description"
          content="Create an EcoTrove account to save on your energy bills while using cleaner, predictable power. ."
        />
        <meta
          name="keywords"
          content="create ecotrove account, affordable clean energy, accessible clean energy, Ali Sarilgan, Ozge Islegen-Wojdyla, clean energy subscription, lower energy bills, EcoTrove, green power, ecotrove team, Jess Kerlin, Tegan Fleishman, Christine Manegan"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="tw-preflight">
        {/* Get quote popup */}
        <dialog id="get-quote-modal" className="daisy-modal px-content-mobile lg:px-content">
          <div className="daisy-modal-box flex flex-col gap-4">
            <h1 className="text-xl">We don't have that email address on file.</h1>
            <p>If you have already received a quote from EcoTrove, please use the original email you provided.</p>
            <p>If not, please get an EcoTrove quote.</p>
            <p>
              Please reach out to{" "}
              <a href="mailto:info@ectrove.com" className="daisy-link daisy-link-primary daisy-link-hover">
                info@ecotrove.com
              </a>{" "}
              with any questions!
            </p>
            <div className="daisy-modal-action">
              <form method="dialog">
                <button className="daisy-btn daisy-btn-primary mr-2" onClick={handleGetQuote}>
                  Get Quote
                </button>
                {/* This button will automatically close the daisy modal */}
                <button className="daisy-btn daisy-btn-secondary">Close</button>
              </form>
            </div>
          </div>
        </dialog>

        <RDCCardWithBackground>
          <h1 className="text-xl">Create Account</h1>
          <form
            className="flex flex-col gap-4"
            onSubmit={createAccount.handleSubmit((data) =>
              handleCreateAccount({ ...data, email: data.email.toLowerCase() })
            )}>
            {/* Name */}
            <label className="flex flex-col gap-default w-full">
              <div className="flex items-center gap-default">
                <UserCircleIcon className="hero-icon hero-icon-sm" />
                <p>Full Name</p>
              </div>
              <input
                type="text"
                className="daisy-input daisy-input-bordered aria-invalid:daisy-input-error aria-invalid:text-error"
                placeholder="Your name"
                aria-invalid={Boolean(errors.name)}
                {...createAccount.register("name", { required: true, pattern: FULL_NAME_REGEX })}
              />
              <RDCValidationError error={errors.name ? "Please enter your full name" : ""} />
            </label>
            {/* Email */}
            <label className="flex flex-col gap-default w-full">
              <div className="flex items-center gap-default">
                <EnvelopeIcon className="hero-icon hero-icon-sm" />
                <p>Email</p>
              </div>
              <input
                type="email"
                className="daisy-input daisy-input-bordered aria-invalid:daisy-input-error aria-invalid:text-error"
                placeholder="Your email address"
                aria-invalid={Boolean(errors.email)}
                {...createAccount.register("email", { required: true, pattern: EMAIL_REGEX })}
              />
              <RDCValidationError error={errors.email ? "Please enter a valid email address" : ""} />
            </label>
            {/* Password + confirmation */}
            <RDCConfirmPasswordsInput form={createAccount} />
            {/* Captcha  */}
            <RDCCaptcha
              captchaKey={captchaKey}
              onSuccess={(token) => setCaptchaToken(token)}
              invalid={captchaInvalid}
            />
            <button className="daisy-btn daisy-btn-primary w-full" type="submit" disabled={loading}>
              Create Account
            </button>
          </form>
          <div className="flex flex-col place-items-center gap-default">
            <p>Already have an account?</p>
            <a className="daisy-link daisy-link-primary daisy-link-hover" href={LOGIN_ROUTE}>
              Log in
            </a>
          </div>
        </RDCCardWithBackground>
      </div>
    </>
  );
};
