import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

/**
 * Interface that represents a user that refers another user.
 */
export interface Referrer {
  email: string;
  name: string;
}

interface ReferrerState {
  referrer: Referrer | undefined;
  setReferrer: (referrer: Referrer) => void;
}

/**
 * Global store for referrer state.
 */
export const useReferrerStore = create<ReferrerState>()(
  persist(
    (set) => ({
      referrer: undefined,
      setReferrer: (referrer) => set({ referrer }),
    }),
    {
      name: "referrer-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
