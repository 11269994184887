import { SubmitHandler, useForm } from "react-hook-form";
import { ZIP_CODE_REGEX } from "../../constants";
import { RDCTextWithButtonInput } from "./RDCTextWithButtonInput";
import { FormProps } from ".";

export interface CheckAvailabilityHeroFieldValues {
  zipCode: string;
}

export const VALIDATIONS: Record<keyof CheckAvailabilityHeroFieldValues, Object> = {
  zipCode: {
    required: true,
    pattern: ZIP_CODE_REGEX,
  },
};

export interface RDCCheckAvailabilityFormProps extends FormProps<keyof CheckAvailabilityHeroFieldValues> {
  onSubmit: SubmitHandler<CheckAvailabilityHeroFieldValues>;
}

/**
 * Form component that allows a user to check if their ZIP code is available for use with EcoTrove.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCCheckAvailabilityForm: React.FC<RDCCheckAvailabilityFormProps> = (props) => {
  const { onSubmit } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CheckAvailabilityHeroFieldValues>();

  return (
    <form className="flex justify-center lg:justify-start" onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="flex flex-col gap-default w-full">
        <RDCTextWithButtonInput
          data-test={props["data-tests"].zipCode}
          name="zipCode"
          placeholder="Check your ZIP code"
          invalid={Boolean(errors.zipCode)}
          register={register}
          validations={VALIDATIONS.zipCode}
          buttonText="Check Savings"
          buttonType="primary"
          maxLength={5}
        />
        {errors.zipCode ? (
          <p className="daisy-label daisy-label-text text-error" role="alert">
            Please enter a valid 5-digit ZIP code
          </p>
        ) : (
          // Add some extra spacing so the screen doesn't jump around when a validation error appears/disappears
          <p className="mb-9"></p>
        )}
      </div>
    </form>
  );
};
