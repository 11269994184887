import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/**
 * Props to pass to a `RDCRedirectPage`.
 */
export interface RDCRedirectPageProps {
  to: string;
  paramKeys?: string[];
}

/**
 * Page that redirects to another page.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCRedirectPage: React.FC<RDCRedirectPageProps> = (props) => {
  const { to, paramKeys } = props;
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const paramString = paramKeys
      ? paramKeys.map((key) => `${key}=${encodeURIComponent(params[key] ?? "")}`).join("&")
      : "";
    const url = `${to}${paramString ? `?${paramString}` : ""}`;
    navigate(url, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
