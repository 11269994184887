/**
 * Props to pass to a `RDCActionCard`.
 */
export interface RDCActionCardProps {
  title: string;
  subTitle?: string;
  body: JSX.Element;
  action:
    | { type: "link"; text: string; href: string; newTab?: boolean; onClick?: () => void }
    | { type: "handler"; text: string; onClick: () => void };
}

/**
 * Card that displays a header, body, and an action to perform.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCActionCard: React.FC<RDCActionCardProps> = (props) => {
  const { title, subTitle, body, action } = props;
  return (
    <div className="flex flex-col justify-between rounded-3xl border-default p-4 gap-5 shadow-sm">
      <div className="flex flex-col gap-default">
        <h2 className="text-xl">{title}</h2>
        {subTitle && <p className="text-sm">{subTitle}</p>}
      </div>
      {body}
      {action.type === "link" && (
        <a
          className="daisy-btn daisy-btn-primary"
          href={action.href}
          target={action.newTab ? "_blank" : undefined}
          rel="noopener noreferrer"
          onClick={action.onClick}>
          {action.text}
        </a>
      )}
      {action.type === "handler" && (
        <button className="daisy-btn daisy-btn-primary" onClick={action.onClick}>
          {action.text}
        </button>
      )}
    </div>
  );
};
