import { useParams } from "react-router-dom";
import { BlogService } from "../services/BlogService";
import { RDCFinishedScrollingForm, RDCFooter, RDCNavbar } from "../components";
import { ALL_BLOGS_ROUTE } from "../../constants";
import { LinkIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";

/**
 * Page that displays a single blog post.
 * @returns a React component.
 */
export const RDCSingleBlogPage: React.FC = () => {
  const { blogId } = useParams();
  const blog = blogId ? BlogService.getBlog(blogId) : undefined;

  useEffect(() => {
    mixpanel.track(`singleBlog: ${blogId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Sharing this state across two buttons will cause them to update the text of eachother,
  // but it's unlikely that the user will hover both links within the text change timeout and even notice.
  const [copyButtonText, setCopyButtonText] = useState<string>("Copy to clipbard");

  const shareButtons = [
    <div className="daisy-tooltip daisy-tooltip-bottom daisy-tooltip-secondary" data-tip={copyButtonText}>
      <button
        className="daisy-btn daisy-btn-ghost daisy-btn-circle"
        key="copy"
        aria-label="Copy to clipboard"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          setCopyButtonText((_) => "Copied!");
          setTimeout(() => setCopyButtonText((_) => "Copy to clipboard"), 1000);
        }}>
        <LinkIcon className="hero-icon size-4" />
      </button>
    </div>,
    // TODO: add more social media share links here
  ];

  return (
    <div className="tw-preflight flex flex-col items-center">
      <RDCNavbar />
      {!blog && <p className="text-center py-36">Blog not found</p>}
      {blog && (
        <div className="py-36 px-content-mobile lg:px-0 lg:w-2/3 space-y-10">
          <div className="flex w-full justify-between items-center">
            {/* Breadcrumbs */}
            <div className="daisy-breadcrumbs text-sm text-neutral-content">
              <ul>
                <li>
                  <a href={ALL_BLOGS_ROUTE}>Blogs</a>
                </li>
                <li>
                  <a href={`${ALL_BLOGS_ROUTE}/${blog.id}`}>{blog.title}</a>
                </li>
              </ul>
            </div>
            {/* Share links */}
            <div className="flex gap-default text-neutral-content">{shareButtons}</div>
          </div>
          {/* Title */}
          <div>
            <div className="daisy-badge daisy-badge-secondary">{blog.category}</div>
            <h1 className="text-4xl lg:text-5xl my-4">{blog.title}</h1>
            <h2 className="text-lg lg:text-xl text-neutral-content">{blog.subtitle}</h2>
          </div>
          {/* Image */}
          <img className="w-full aspect-video rounded-3xl" src={blog.img.src} alt={blog.img.alt} />
          {/* Content */}
          {blog.content}
          {/* Social media and tags */}
          <div className="flex flex-col gap-default justify-center items-center">
            <p className="text-sm">Share this post</p>
            <div className="flex gap-default text-neutral-content">{shareButtons}</div>
          </div>
          <div className="flex flex-wrap w-full justify-center gap-4">
            {blog.tags.map((tag) => (
              <div className="daisy-badge daisy-badge-neutral" key={tag}>
                {tag}
              </div>
            ))}
          </div>
          <hr />
          {/* Author */}
          <div className="flex flex-col w-full items-center">
            <img className="daisy-mask daisy-mask-circle size-12" src={blog.author.img.src} alt={blog.author.img.alt} />
            <p className="mt-default text-sm">Author</p>
            <p>{blog.author.name}</p>
          </div>
        </div>
      )}

      {/* View height: finished scrolling & footer */}
      <div className="flex flex-col min-h-dvh pt-16 w-full">
        <RDCFinishedScrollingForm />
        <div className="grow" />
        <RDCFooter />
      </div>
    </div>
  );
};
