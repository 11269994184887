import { useEffect, useState } from "react";

/**
 * Props to pass to a `RDCAutoScrollCarousel`.
 */
interface RDCAutoScrollCarouselProps {
  carouselId: string;
  items: JSX.Element[];
  autoScrollMS: number;
}

/**
 * Carousel component that automatically scrolls between items.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCAutoScrollCarousel: React.FC<RDCAutoScrollCarouselProps> = (props) => {
  const { carouselId, items, autoScrollMS } = props;
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  /**
   * Helper method that scrolls to the next item in the carousel.
   */
  const scrollToNextItem = () => {
    const nextIndex = (currentIndex + 1) % items.length;
    const carousel = document.getElementById(carouselId);
    const target = document.querySelector<HTMLDivElement>(`#${carouselId}-${nextIndex}`);
    const left = target?.offsetLeft;
    carousel?.scrollTo({ left });
    setCurrentIndex((prev) => (prev + 1) % items.length);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      scrollToNextItem();
    }, autoScrollMS);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <div id={carouselId} className="daisy-carousel overflow-hidden py-2">
      {items.map((item, i) => {
        return (
          <div key={i} id={`${carouselId}-${i}`} className="daisy-carousel-item px-4">
            {item}
          </div>
        );
      })}
    </div>
  );
};
