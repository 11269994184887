import { useState, useEffect, useRef } from "react";
import { handleStartStripe } from "../../startStripe"; //TODO remove one layer of this when we move the file
import { useAlertStore, useReferrerStore } from "../stores";
import { Utils } from "../../utils";
import mixpanel from "mixpanel-browser";
import { useNavigate, useSearchParams } from "react-router-dom";
import { STATUS_403_ROUTE } from "../../constants";

/**
 * Page for selecting the subscription start date. The start date can be up to a month from now,
 * and redirects to DocuSign if the user hasn't chosen a date within 30 seconds. If a user doesn't choose a start date,
 * the default date is today.
 * @returns A React `FunctionComponent`.
 */
export const RDCCustomizeQuotePage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [authorized, setAuthorized] = useState<boolean>(false);
  const today = new Date();
  const maxDate = new Date(today.getUTCFullYear(), today.getUTCMonth() + 1, today.getUTCDate());
  const [selectedDate, setSelectedDate] = useState<string>(today.toISOString().split("T")[0]);
  const { setErrorAlert } = useAlertStore();
  const timeoutIdRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const email = searchParams.get("email");

  // Check if a referrer exists in the store (it would have been set by the landing page)
  const { referrer } = useReferrerStore();
  const [discountCode, setDiscountCode] = useState<string | undefined>();

  useEffect(() => {
    mixpanel.track("customizeQuote");

    // If the user is already paying, they should not be able to access this page
    const checkAuthorized = async () => {
      if (email && !(await Utils.canGetQuote(email))) {
        navigate(STATUS_403_ROUTE, { replace: true });
      } else {
        setAuthorized(true);
      }
    };
    checkAuthorized();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleContinue = async () => {
    try {
      setSubmitDisabled(true);
      if (!email) {
        setErrorAlert("Error: Session Expired.");
        return;
      }
      clearTimeout(timeoutIdRef.current);
      const startDate = new Date(selectedDate);
      await handleStartStripe({ email, startDate, discountCode });
    } catch (error) {
      setErrorAlert(
        "An error occurred while selecting your subscription. Please try again later or contact info@ecotrove.com."
      );
    } finally {
      setSubmitDisabled(false);
    }
  };

  // Check if a referrer discount should be applied
  useEffect(() => {
    const checkReferrer = async () => {
      try {
        setDiscountCode(referrer && (await Utils.isPayingUser(referrer.email)).result ? referrer.email : undefined);
      } catch (error) {
        setErrorAlert("An error occurred while checking referral code. Please try again later.");
      }
    };

    checkReferrer();
  }, [referrer, setErrorAlert]);

  useEffect(() => {
    const resetTimer = async () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current); // Clear the previous timeout if any
      }
      timeoutIdRef.current = setTimeout(async () => {
        console.log("User has been inactive for 30 seconds.");
        await handleContinue();
      }, 30000); // 30 seconds in milliseconds
    };
    resetTimer();

    // Cleanup function to clear timeout on component unmount or before next effect run
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !authorized ? (
    <></>
  ) : (
    <div className="tw-preflight bg-base-100 min-h-screen relative">
      {/* Main content */}
      <div className="flex justify-center items-center min-h-screen">
        <div className="daisy-card w-full max-w-lg bg-white rounded-lg shadow-lg p-6">
          <h1 className="text-2xl font-bold text-primary mb-4">Your Subscription Start Date</h1>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await handleContinue();
            }}
            className="flex flex-col gap-4">
            {/* Date Picker */}
            <label htmlFor="startDate" className="text-sm text-dark">
              Select your desired subscription start date:
            </label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={selectedDate}
              onChange={handleDateChange}
              min={today.toISOString().split("T")[0]}
              max={maxDate.toISOString().split("T")[0]}
              className="input input-bordered w-full"
              aria-label="Start Date"
              disabled={submitDisabled}
            />
            <button className="daisy-btn daisy-btn-primary" type="submit" disabled={submitDisabled}>
              Continue
            </button>
          </form>
          <p className="text-sm text-neutral-content mt-6">
            Begin saving today! Your 12-month EcoTrove subscription will start on the selected date, allowing EcoTrove
            to pay your next 12 PG&E bills. Cancel anytime.
          </p>
          <p className="text-sm text-neutral-content mt-2">
            We selected the earliest possible start date. You can choose another if desired.
          </p>
        </div>
      </div>
    </div>
  );
};
