import { create } from "zustand";

export interface AlertState {
  // Success
  successAlert: string;
  setSuccessAlert: (message: string) => void;
  // Warning
  warningAlert: string;
  setWarningAlert: (message: string) => void;
  // Error
  errorAlert: string;
  setErrorAlert: (message: string) => void;
}

/**
 * Global store for alert banner states.
 */
export const useAlertStore = create<AlertState>()((set) => ({
  // Success
  successAlert: "",
  setSuccessAlert: (message) => set({ successAlert: message }),
  // Warning
  warningAlert: "",
  setWarningAlert: (message) => set({ warningAlert: message }),
  // Error
  errorAlert: "",
  setErrorAlert: (message) => set({ errorAlert: message }),
}));
