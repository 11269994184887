import { useCallback, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import { supabase } from "../../supabaseClient"; //TODO remove layer
import { constructFullApiURL } from "../../constructFullApiUrl"; //TODO remove layer
import { redirectToTesting } from "../../redirectToTesting"; //TODO remove layer
import { useNavigate } from "react-router-dom";
import { RDCRotatingCard, RDCRotatingChecks, RDCProgressBar } from "../components";

import {
  SHOW_QUOTE_ROUTE,
  THANKS_FOR_REAUTHORIZING_ROUTE,
  STATUS_403_ROUTE,
  SHOW_SOLAR_QUOTE_ROUTE,
  SHOW_BUSINESS_QUOTE_ROUTE,
} from "../../constants";
import { Utils } from "../../utils";

export const RDCPgeOAuthRedirectPage: React.FC = () => {
  const [stage, setStage] = useState("Fetching token");
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [authorized, setAuthorized] = useState<boolean>(true);
  const loadingSteps = [
    { id: 1, label: "Analyzing your electricity data", completed: true },
    { id: 2, label: "Finding you the best rates", completed: false },
    { id: 3, label: "Checking the incentives", completed: false },
    { id: 4, label: "Preparing your quote", completed: false },
  ];

  useEffect(() => {
    mixpanel.track("pgeOAuthRedirect");
    // If the user is already paying, they should not be able to access this page, and their quote should not be rerun
    const checkAuthorized = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const state = queryParams.get("state");
      if (state) {
        const email = state.slice(2);
        if (email && !(await Utils.canGetQuote(email))) {
          setAuthorized(false);
          navigate(STATUS_403_ROUTE, { replace: true });
        }
      }
    };
    checkAuthorized();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if we do use email input
  // interface checkEmailFormValues {
  //   email: string;
  // }
  // const SUBMIT_ERROR_EMAIL_VALIDATIONS: Record<keyof checkEmailFormValues, Object> = {
  // IMPORTANT: if this is used, make sure you set the email to lower case before submitting. See other examples in this code base
  //   email: { required: true, pattern: EMAIL_REGEX },
  // };
  // const checkEmail = useForm<checkEmailFormValues>();

  const navigate = useNavigate();

  interface TrackingFlags {
    unexpTokenFetch: boolean;
    generateQuote: boolean;
    showQuote: boolean;
    docusign: boolean;
    quoteError: boolean;
    insufficientDataForQuote: boolean;
    unexpSubmitEmail: boolean;
    // Index signature
    [key: string]: boolean;
  }

  const [trackingFlags, setTrackingFlags] = useState<TrackingFlags>({
    unexpTokenFetch: false,
    generateQuote: false,
    showQuote: false,
    docusign: false,
    quoteError: false,
    insufficientDataForQuote: false,
    unexpSubmitEmail: false,
  });

  const trackEventOnce = (eventName: string) => {
    // TODO: Delete log statements below
    console.log("Tracking: ", eventName);
    console.log("Tracking Flags: ", trackingFlags);
    if (!trackingFlags[eventName]) {
      mixpanel.track(eventName);
      setTrackingFlags({ ...trackingFlags, [eventName]: true });
    }
  };

  const handleError = async (email: string, reason: string) => {
    const { error } = await supabase
      .from("failed-quote-generation")
      .insert([{ email: email, reason: reason }])
      .select();

    if (error) {
      console.log("Error updating status of user: ", error);
    }
  };

  const checkQuoteStatus = useCallback(
    (taskId: string) => {
      const navigateToShowQuote = (taskId: string, email: string) => {
        const params = new URLSearchParams();
        try {
          params.append("taskId", taskId);
          params.append("email", email);
        } catch {
          console.log("no task Id");
          return;
        }
        // Construct the full URL
        const showQuoteUrl = `${SHOW_QUOTE_ROUTE}?${params.toString()}`;
        // Use the navigate function to navigate to the constructed URL
        navigate(showQuoteUrl);
      };

      console.log("Start of call");
      const apiURL = constructFullApiURL(`/task-status/${taskId}`);
      fetch(apiURL)
        .then((response) => response.json())
        .then((data) => {
          console.log("calling", data);

          if (data.status === "complete") {
            const queryParams = new URLSearchParams(window.location.search);
            const state = queryParams.get("state");
            console.log("state: ", state);
            let emailVal = null;
            let skipQuote = null;
            if (state) {
              skipQuote = state.charAt(1);
              emailVal = state.slice(2);
            } else {
              setStage("Error Generating Quote");
              return;
            }
            if (skipQuote === "1") {
              //business code
              navigate(SHOW_BUSINESS_QUOTE_ROUTE);
            } else if (skipQuote === "2") {
              //reauthorization code
              navigate(`${THANKS_FOR_REAUTHORIZING_ROUTE}?email=${encodeURIComponent(emailVal)}`);
            } else {
              console.log("Task complete, data:", data.data);
              // Create a new URLSearchParams object and append the parameters
              navigateToShowQuote(taskId, emailVal);
            }
          } else if (data.status === "error") {
            const queryParams = new URLSearchParams(window.location.search);
            const state = queryParams.get("state");
            let emailVal = null;
            if (state) {
              emailVal = state.slice(2);
              handleError(emailVal, data.message);
              console.log(data);
              if (data.message === "Insufficient Data") {
                navigateToShowQuote(taskId, emailVal);
              }
              if (data.message === "PGE Error") {
                setStage("PGE Error");
              } else if (data.message === "Solar") {
                navigate(SHOW_SOLAR_QUOTE_ROUTE);
              } else if (data.message === "Business Plan Not Available") {
                navigate(SHOW_BUSINESS_QUOTE_ROUTE);
              } else {
                console.error("Error in task:", data.message);
                setStage("Error Generating Quote");
              }
            }
          } else {
            console.log("Task still processing...");
            setTimeout(() => checkQuoteStatus(taskId), 4000); // Check again in 4 seconds
          }
        });
    },
    [navigate, setStage]
  );

  const exchangeAuthorizationCodeForAccessToken = useCallback(
    (authCode: string, email: string, userType?: string) => {
      console.log("AUTH CODE: ", authCode);
      console.log("EMAIL: ", email);
      let skipQuote = false;
      if (userType !== "0") {
        //for reauthorization & business only, we do not show 'fetch quote' screen
        skipQuote = true;
      }

      const apiURL = constructFullApiURL("/auth-code-exchange");
      fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: authCode,
          email: email,
          userType: userType,
        }),
      })
        .then((response: any) => {
          if (!response.ok) {
            throw new Error("Failed to exchange authorization code for access token.");
          }
          return response.json();
        })
        .then((data) => {
          console.log("data:", data);
          if (!skipQuote) {
            setStage("Fetching quote");
          }
          checkQuoteStatus(data.taskId);
        })
        .catch((error) => {
          console.error("Error:", error);
          setStage("Error Generating Quote");
        });
    },
    [checkQuoteStatus]
  );

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.search;
    console.log("URL BEFORE REDIRECT", currentUrl);
    // Extract query parameters from the URL
    const urlParams = new URLSearchParams(currentUrl);
    redirectToTesting(urlParams, "pge-oauth-redirect");
  }, []);

  useEffect(() => {
    if (!authorized) return;
    const requiredScopes = new Set([
      "1",
      "3",
      "8",
      "13",
      "14",
      "18",
      "19",
      "31",
      "32",
      "35",
      "37",
      "38",
      "39",
      "4",
      "5",
      "15",
      "16",
      "46",
      "47",
    ]);
    const extractScopesFromURL = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const scopeString = urlParams.get("scope");
      if (scopeString) {
        const scopes = scopeString.split(";").reduce((acc, curr) => {
          const [key, value] = curr.split("=");
          if (key === "FB") {
            value.split("_").forEach((scope) => acc.add(scope));
          }
          return acc;
        }, new Set());
        return scopes;
      }
    };
    const checkRequiredScopes = (scopes: any) => {
      return [...requiredScopes].every((scope) => scopes.has(scope));
    };
    const scopes = extractScopesFromURL();
    if (!checkRequiredScopes(scopes)) {
      setShowRetryButton(true);
    } else {
      setShowRetryButton(false);
    }
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");
    let emailVal = null;
    let userType = "0";
    if (state) {
      userType = state.charAt(1);
      emailVal = state.slice(2);
    } else {
      setStage("Error Generating Quote");
      return;
    }

    console.log("user type: 0 for residential, 1 for business, 2 for reauthorization", userType);

    if (code && emailVal) {
      // setAuthCode(code);
      console.log("code", code);
      exchangeAuthorizationCodeForAccessToken(code, emailVal, userType);
    }
  }, [exchangeAuthorizationCodeForAccessToken, authorized]);

  const renderStageComponent = () => {
    switch (stage) {
      case "Fetching token":
        trackEventOnce("unexpTokenFetch");
        return (
          <div className="text-center">
            <h1 className="mt-8 text-xl  text-black">Completing PG&E connection...</h1>
            <p className="text-sm text-gray-600 mt-2">This will take a few moments</p>
          </div>
        );

      case "Fetching quote":
        trackEventOnce("generateQuote");
        return (
          <div className="text-center">
            {/* Title */}
            <h1 className="text-2xl  text-black mt-8">Preparing your guaranteed savings...</h1>
            <p className="text-base text-gray-700 mt-2">This will take about 1 minute</p>

            {/* Progress Bar */}
            <RDCProgressBar />

            {/* Rotating Steps */}
            <RDCRotatingChecks steps={loadingSteps} />

            {/* Rotating Card */}
            <RDCRotatingCard />
          </div>
        );

      case "Error Generating Quote":
        return (
          <div className="text-center">
            <h1 className="text-xl  text-black">Our apologies, we ran into an error generating your quote.</h1>
            <p className="text-sm text-gray-700 mt-4">We will notify you when we are available or try again.</p>
          </div>
        );

      case "Insufficient Data":
        trackEventOnce("insufficientDataForQuote");
        return (
          <div className="text-center">
            <h1 className="text-xl  text-black">Your account is too new for us to forecast your energy demand.</h1>
            <p className="text-sm text-gray-700 mt-4">We will notify you as soon as you are eligible.</p>
          </div>
        );
      case "PGE Error":
        trackEventOnce("PGEError");
        return (
          <div className="text-center">
            <h1 className="text-xl  text-black">Apologies, PG&E's service is temporarily unavailable.</h1>
            <p className="text-sm text-gray-700 mt-4">
              Unfortunately, we're unable to connect to PG&E at this time. We will notify you as soon as the service is
              restored.
            </p>
          </div>
        );

      default:
        return null;
    }
  };

  const handleReturnPGE = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const scopeString = urlParams.get("scope");
    let brValue = "";

    if (scopeString !== null) {
      // Split the scope string by semicolon and loop through each segment
      scopeString.split(";").forEach((item) => {
        const [key, value] = item.split("=");
        // Check if the current segment contains the BR key
        if (key === "BR") {
          brValue = value;
        }
      });
    }

    if (brValue) {
      const retryUrl = `https://sharemydata.pge.com/myAuthorization/auth/new/${brValue}`;
      window.location.href = retryUrl;
    } else {
      console.error("BR value not found in URL. Cannot retry authorization.");
    }
  };

  return (
    <div className="tw-preflight min-h-screen flex flex-col items-center justify-center bg-base-100">
      {/* Main Card */}
      <div className="daisy-card daisy-shadow-lg w-full max-w-lg p-6">
        {showRetryButton ? (
          <div className="text-center">
            <h2 className="text-xl  text-warning">Oops, we need a bit more information</h2>
            <p className="text-neutral-content mt-4">
              In order to provide your quote, we will need access to all your data available at PG&E.
            </p>
            <button className="daisy-btn daisy-btn-secondary mt-4" onClick={handleReturnPGE}>
              Go back to PG&E
            </button>
          </div>
        ) : (
          renderStageComponent()
        )}
      </div>
    </div>
  );
};
