import { Link } from "./types";
import { z } from "zod";
import * as tailwindColors from "tailwindcss/colors";

/* ---- MISC ---- */
export const UTILITIES_SERVED = ["PACIFIC GAS AND ELECTRIC"];

/* ---- ECOTROVE ---- */
export const ECOTROVE_URL = "https://ecotrove.com";
export const ECOTROVE_EMAIL_INFO = "info@ecotrove.com";
export const ECOTROVE_TEST_EMAIL = "info@ecotrove.com";

/* ---- BUSINESS LOGIC ---- */
export const REFERRAL_DISCOUNT = 50;
export const FEEDBACK_DISCOUNT = 20;

/* ---- UTILTIITES ---- */
export const PGE_URL = "https://sharemydata.pge.com/myAuthorization";
export const SCE_URL = "https://www.sce.com";
export const SDGE_URL = "https://www.sdge.com";
export const DATAGUARD_URL = "https://www.energy.gov/oe/dataguard-energy-data-privacy-program";
export const TRUSTPILOT_URL = "https://www.trustpilot.com/review/ecotrove.com";
export const SMARTENERGY_URL =
  "https://www.smart-energy.com/renewable-energy/delivering-clean-energy-at-a-fixed-price-in-the-us/";

/* ---- ROUTES ---- */
export const REDESIGN_ROUTE = "/rdc"; // TODO: deprecate this after redesign
export const HOW_IT_WORKS_ROUTE = "/how-it-works";
export const ABOUT_US_ROUTE = "/about-us";
export const ALL_BLOGS_ROUTE = "/blogs";
export const BLOGS_ROUTE = "/blogs";
export const PGE_OAUTH_REDIRECT_ROUTE = "/pge-oauth-redirect";
export const CUSTOMIZE_QUOTE_ROUTE = "/customize-quote";
export const PAYMENT_SUCCESS_ROUTE = "/payment-success";
export const GET_QUOTE_ROUTE = "/get-quote";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const TERMS_OF_SERVICE_ROUTE = "/terms-of-service";
export const AGREE_TO_TERMS_ROUTE = "/terms-and-conditions";
export const USER_DASHBOARD_ROUTE = "/user-dashboard";
export const USER_QUESTIONS_ROUTE = "/user-questions";
export const ACCOUNT_SETTINGS_ROUTE = "/account-settings";
export const SHOW_QUOTE_ROUTE = "/show-quote";
export const SHOW_SOLAR_QUOTE_ROUTE = "/show-quote/solar";
export const SHOW_BUSINESS_QUOTE_ROUTE = "/show-quote/business";
export const MANAGE_ACCOUNT_ROUTE = "/manage-account";
export const SAVE_MORE_ROUTE = "/save-more";
export const FAQ_ROUTE = "/faqs";
export const THANKS_FOR_REAUTHORIZING_ROUTE = "/thanks-for-reauthorizing";
export const REFER_ROUTE = "/refer";
export const LOGIN_ROUTE = "/login";
export const CREATE_ACCOUNT_ROUTE = "/create-account";
export const UPDATE_PASSWORD_ROUTE = "/update-password";
export const CONFIRM_EMAIL_ROUTE = "/confirm-email";
export const SUPPORT_ROUTE = "/support";

// Status code routes
export const STATUS_403_ROUTE = "/403";

/* ---- JSX ---- */
/**
 * Space to add at the bottom on mobile so the next view doesn't get snapped too soon.
 */
export const MOBILE_SNAP_SPACER = <div className="flex lg:hidden h-48" />;

/* ---- REGEX ---- */

/**
 * Regex to validate a person's name (from https://regexr.com/3f8cm).
 */
export const FULL_NAME_REGEX = /^\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+$/;

/**
 * Regex to validate an email (from https://regexr.com/3e48o).
 * IMPORTANT: This regex does NOT validate case sensitivity. Therefore, emails should be formatted as lower case before storing.
 */
export const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

/**
 * Regex to validate a 5-digit ZIP code.
 */
export const ZIP_CODE_REGEX = /^\d{5}$/;

/* ---- ECHARTS ---- */
/**
 * TODO: import theme colors from Tailwind config.
 * I tried this guide without success: https://stackoverflow.com/questions/72440619/import-styles-directly-from-tailwindcss-config-file
 * I suspect our preflight plugin is messing with the imports.
 * Some example theme configs can be found here: https://github.com/apache/echarts/tree/master/theme
 */
export const ECHARTS_PRIMARY = "#380089";
export const ECHARTS_SECONDARY = "#DBD9EC";
export const ECHARTS_NEUTRAL = tailwindColors.stone[200];
export const ECHARTS_NEUTRAL_CONTENT = tailwindColors.stone[600];
export const ECHARTS_TEXT_STYLE = {
  color: tailwindColors.gray[950],
  fontFamily: "Gilroy-Medium",
  fontSize: 18,
};
export const ECHARTS_THEME = {
  textStyle: ECHARTS_TEXT_STYLE,
  color: [ECHARTS_PRIMARY, ECHARTS_SECONDARY, ECHARTS_NEUTRAL, ECHARTS_NEUTRAL_CONTENT],
  categoryAxis: { axisLabel: ECHARTS_TEXT_STYLE },
  valueAxis: { axisLabel: ECHARTS_TEXT_STYLE },
};
export const ECHARTS_BAR_STYLE = {
  borderRadius: [5, 5, 0, 0],
};

/* ---- SUPABASE ---- */
export const SUPABASE_UNAUTHENTICATED_ERROR_CODE = "42501";

/* ---- LINK CONFIGS ---- */
/**
 * Links that are shown in main navigation menus for authenticated users.
 */
export const MAIN_MENU_LINKS_AUTH: Link[] = [
  { label: "Dashboard", url: USER_DASHBOARD_ROUTE },
  { label: "Account Settings", url: ACCOUNT_SETTINGS_ROUTE },
  { label: "Support", url: SUPPORT_ROUTE },
];
/**
 * Links that are shown in main navigation menus for unauthenticated users.
 */
export const MAIN_MENU_LINKS_UNAUTH: Link[] = [
  { label: "How It Works", url: HOW_IT_WORKS_ROUTE },
  { label: "About Us", url: ABOUT_US_ROUTE },
  { label: "Blog", url: ALL_BLOGS_ROUTE },
];
/**
 * Links that prompt a user to take action.
 */
export const ACTION_LINKS = {
  unauthenticated: { label: "Check Savings", url: GET_QUOTE_ROUTE },
  authenticated: { label: "Refer a Friend", url: REFER_ROUTE },
};
/** Link to Ali's calendly for a custom quote. */
export const CALENDLY_URL_ALI = "https://calendly.com/ali-sarilgan/ecotrove-call";
/** Link to Type Form to manually enter details. */
export const TYPEFORM_CONNECT_UTILITY = "https://dinlmon2rl8.typeform.com/ecotrove";

/* ---- ZOD SCHEMAS ---- */
/**
 * Zod schema for validationg a utility provider.
 */
export const UTILITY_PROVIDER_SCHEMA = z.enum(["pge"]);
/**
 * Zod schema for validationg a building type.
 */
export const SAVINGS_TYPE_SCHEMA = z.enum(["household", "business"]);
