import { RDCAlertBanner } from "./RDCAlertBanner";
import { useAlertStore } from "../stores";

/**
 * Component that displays multiple alert banners at the top of the page.
 * @param props the props to render the component with.
 * @returns a React component.
 */
export const RDCAlertBanners: React.FC = () => {
  const { successAlert, setSuccessAlert, warningAlert, setWarningAlert, errorAlert, setErrorAlert } = useAlertStore();
  return (
    <div className="tw-preflight fixed top-0 left-0 right-0 z-50">
      {Boolean(successAlert) && (
        <RDCAlertBanner type="success" message={successAlert} onClickClose={() => setSuccessAlert("")} />
      )}
      {Boolean(warningAlert) && (
        <RDCAlertBanner type="warning" message={warningAlert} onClickClose={() => setWarningAlert("")} />
      )}
      {Boolean(errorAlert) && (
        <RDCAlertBanner type="error" message={errorAlert} onClickClose={() => setErrorAlert("")} />
      )}
    </div>
  );
};
