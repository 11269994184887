import React, { useState, useEffect } from "react";
import { RDCFooter, RDCNavbar } from "../components";
import mixpanel from "mixpanel-browser";

/**
 * Scrolls smoothly to the section corresponding to the given ID.
 */
const scrollToSection = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

/**
 * TermsOfService Component
 * @description Displays the EcoTrove Terms of Service with a navigation menu.
 */
export const RDCTermsOfServicePage: React.FC = () => {
  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    mixpanel.track("termsOfService");
  }, []);

  useEffect(() => {
    const handleAnchorClick = (event: MouseEvent) => {
      const target = event.target as HTMLAnchorElement;
      if (target?.hash) {
        const id = target.hash.substring(1);
        scrollToSection(id);
      }
    };

    document.addEventListener("click", handleAnchorClick);
    return () => {
      document.removeEventListener("click", handleAnchorClick);
    };
  }, []);

  useEffect(() => {
    // Check if the "iframe" query parameter is present
    const params = new URLSearchParams(window.location.search);
    if (params.get("iframe") === "true") {
      setIsIframe(true);
    }
  }, []);

  return (
    <div className="tw-preflight">
      {/* Navbar */}
      {!isIframe && <RDCNavbar />}

      {/* Header Section */}
      <div className="col-span-1 lg:col-span-2">
        <div
          className={`max-w-5xl mx-auto ${!isIframe ? "pt-20" : ""}`}
          /* Adjust 'pt-20' based on navbar height */
        >
          <h1 className="text-4xl font-bold">EcoTrove Terms of Service</h1>
          <p className="text-neutral-content mt-2">Effective date: October 1st, 2024</p>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="bg-base-100 text-base-content py-8 px-4">
        <div className="max-w-5xl mx-auto space-y-8">
          {/* Section: Introduction */}
          <section>
            <p className="mt-4">
              Welcome to EcoTrove. Please read on to learn the rules and restrictions that govern your use of our
              Services. If you have any questions, comments, or concerns, please contact us at:
            </p>
            <ul className="list-disc pl-5 mt-4">
              <li>
                Email:{" "}
                <a href="mailto:info@ecotrove.com" className="text-primary underline">
                  info@ecotrove.com
                </a>
              </li>
              <li>Phone: +1 (415) 237 - 1740</li>
              <li>Address: 2 Embarcadero Center, Floor 8, San Francisco, CA 94111, USA</li>
            </ul>
          </section>
          <section>
            <p className="mt-4">
              These Terms of Use or Terms of Service (the “Terms”) are a binding contract between you and EcoTrove, Inc.
              (“EcoTrove,” “we” and “us”). You represent and warrant that you are an individual of legal age to form a
              binding contract (or if not, you’ve received your parent’s or guardian’s permission to use the Services
              and have gotten your parent or guardian to agree to these Terms on your behalf). If you’re agreeing to
              these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to
              agree to these Terms on that organization’s or entity’s behalf and bind them to these Terms (in which
              case, the references to “you” and “your” in these Terms, except for in this sentence, refer to that
              organization or entity). Your use of the Services in any way means that you agree to all of these Terms,
              and these Terms will remain in effect while you use the Services. These Terms include the provisions in
              this document as well as those in the Privacy Policy. Your use of or participation in certain Services may
              also be subject to additional policies, rules and/or conditions (“Additional Terms”), which are
              incorporated herein by reference, and you understand and agree that by using or participating in any such
              Services, you agree to also comply with these Additional Terms. Please read these Terms carefully. They
              cover important information about Services provided to you and any charges, taxes, and fees we bill you.
              These Terms include information about:
            </p>
          </section>

          {/* Section: Table of Contents */}
          <section id="table-of-contents">
            <ul className="list-disc pl-5 mt-4 space-y-2">
              <li>
                <a href="#future-changes" className="text-primary underline">
                  Future changes to these Terms
                </a>
              </li>
              <li>
                <a href="#auto-renewal" className="text-primary underline">
                  Automatic renewals
                </a>
              </li>
              <li>
                <a href="#limitations-of-liability" className="text-primary underline">
                  Limitations of liability
                </a>
              </li>
              <li>
                <a href="#arbitration-agreement" className="text-primary underline">
                  Class action waiver and arbitration
                </a>
              </li>
              <li>
                <a href="#cost-of-services" className="text-primary underline">
                  Cost and terms of services
                </a>
              </li>
            </ul>
          </section>

          {/* Section: Arbitration Notice */}
          <section>
            <p className="mt-4">
              <strong>
                PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS;
              </strong>{" "}
              IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
              <strong>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</strong> EXCEPT FOR CERTAIN TYPES OF DISPUTES
              DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE
              RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION
              LAWSUIT OR CLASS-WIDE ARBITRATION.
            </p>
          </section>

          {/* Service Changes */}
          <section id="future-changes">
            <h2 className="text-2xl font-bold">Will EcoTrove ever change the Services?</h2>
            <p className="mt-4">
              We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits
              on certain features or restrict access to parts or all of the Services. We reserve the right to remove any
              Content (as defined below) from the Services at any time, for any reason (including, but not limited to,
              if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and
              without notice.
            </p>
          </section>

          {/* Service Changes */}
          <section id="future-changes-2">
            <h2 className="text-2xl font-bold">Will these Terms ever change?</h2>
            <p className="mt-4">
              These Terms may need to change along with our Services. We reserve the right to change the Terms at any
              time, but if we do, we will place a notice on our site located at{" "}
              <a
                href="https://ecotrove.com"
                className="text-primary underline hover:text-primary-focus transition-colors">
                https://ecotrove.com
              </a>
              , send you an email, and/or notify you by some other means. If you don’t agree with the new Terms, you are
              free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use
              the Services in any way after a change to the Terms is effective, that means you agree to all of the
              changes. Except for changes by us as described here, no other amendment or modification of these Terms
              will be effective unless in writing and signed by both you and us.
            </p>
          </section>

          {/* Privacy Policy */}
          <section id="privacy-policy">
            <h2 className="text-2xl font-bold">What about my privacy?</h2>
            <p className="mt-4">
              EcoTrove takes the privacy of its users very seriously. For the current EcoTrove Privacy Policy, please
              click{" "}
              <a
                href="https://ecotrove.com/privacy-policy"
                className="text-primary underline hover:text-primary-focus transition-colors">
                here
              </a>
              .
            </p>
          </section>

          <section id="coppa">
            <h2 className="text-2xl font-bold">Children’s Online Privacy Protection Act</h2>
            <p className="mt-4">
              The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain
              parental consent before they knowingly collect personally identifiable information online from children
              who are under 13 years of age. We do not knowingly collect or solicit personally identifiable information
              from children under 13 years of age; if you are a child under 13 years of age, please do not attempt to
              register for or otherwise use the Services or send us any personal information. If we learn we have
              collected personal information from a child under 13 years of age, we will delete that information as
              quickly as possible. If you believe that a child under 13 years of age may have provided us personal
              information, please contact us at{" "}
              <a href="mailto:info@ecotrove.com" className="text-primary underline">
                info@ecotrove.com
              </a>
              .
            </p>
          </section>

          <section id="ecotrove-basics">
            <h2 className="text-2xl font-bold">What are the basics of using EcoTrove?</h2>
            <p className="mt-4">
              As part of the Services, you have the option to subscribe to EcoTrove’s energy bill payment platform (the
              “Subscription”). Our Subscriptions are customized for you, and dependent on the services and products
              offered by your local utility and energy provider (your “Energy Provider”), and the prices charged
              therefore. The terms and conditions applicable to your Subscription are included in these Terms.
            </p>
            <p className="mt-4">
              Your Subscription will start on the date of your first payment of your Monthly Fee (as defined below) to
              EcoTrove, continue for twelve (12) months, and may be automatically renewed for successive twelve (12)
              month terms with notice given to you at least 1 week in advance to reject the auto-renewal. You can cancel
              your Subscription at any time by contacting us at{" "}
              <a href="mailto:info@ecotrove.com" className="text-primary underline">
                info@ecotrove.com
              </a>{" "}
              or on your account page{" "}
              <a href="https://ecotrove.com/manage-account" className="text-primary underline">
                https://ecotrove.com/manage-account
              </a>
              .
            </p>
            <p className="mt-4">
              Your Subscription is a Paid Service (as defined below), and the prices charged therefore are described in
              “Do the Services cost anything?” section below.
            </p>
            <p className="mt-4">
              You acknowledge and agree that, in order for us to provide your Subscription, you must: (1) electronically
              execute an “Energy Agent Agreement”, which will be provided to you by us and which will allow us to work
              with your Energy Provider on your behalf; (2) provide us with ongoing and uninterrupted access to your
              online account with your Energy Provider (your “Energy Account”), by authorizing us to access your Energy
              Provider’s online data sharing platform; (3) notify us whenever you move and change the address at which
              you receive services from your Energy Provider; and (4) provide us accurate, truthful, and complete
              information in response to any questions we ask you regarding the Services. You represent and warrant that
              you will comply with the above requirements in order for us to provide your Subscription and any failure
              to do so may result in us terminating your Subscription.
            </p>
            <p className="mt-4">
              Our goal is to lower your monthly utility bill with your Energy Provider. In order to help us provide the
              Services to you, you acknowledge and agree that you will sign the Energy Agent Agreement we provide to
              you, and as stated in the Energy Agent Agreement, you understand and agree that we may request information
              and take actions on your behalf, including but not limited to, requesting and receiving billing records
              and billing history, request rate changes, place your energy bills on a payment plan with your Energy
              Provider, and enroll you in certain programs offered by your Energy Provider. You further acknowledge and
              agree that your Monthly Fee is contingent on our ability to request this information and take actions on
              your behalf and, failure to allow us to do so may result in a change to your Monthly Fee.
            </p>
          </section>

          {/* Quote Section */}
          <section id="quote-section">
            <h2 className="text-2xl font-bold">Quote</h2>
            <p className="mt-4">
              Prior to signing up for a Subscription, you have the option to request a quote (“Quote”) from us. The
              Quote will include certain information about your proposed Subscription, including your estimated Monthly
              Fee (as described below). The Quote is not a binding contract between you and us; it does not in any way
              obligate you to purchase Subscription, nor does it in way any require us to provide you with a
              Subscription or otherwise guarantee your Monthly Fee or any other Services. The Quote will include a
              forecast of your Monthly Fee, which we calculate based on information provided to us by your Energy
              Provider, however the Monthly Fee included in the Quote is subject to change as stated in these Terms. We
              are only able to provide Quotes and Subscriptions to customers of certain Energy Providers, the list of
              which can be located through the logos of the Energy Utilities listed on our homepage, ecotrove.com. If
              you are not a customer of any of the listed Energy Providers, we cannot provide you with a Quote or
              Subscription.
            </p>
            <p className="mt-4">
              In order to receive a Quote, you must provide us with access to your Energy Account by authorizing
              EcoTrove to access your Energy Provider’s online data-sharing platform through your Energy Account.
            </p>
          </section>

          {/* Account Section */}
          <section id="account-section">
            <h2 className="text-2xl font-bold">EcoTrove Account</h2>
            <p className="mt-4">
              You may be required to sign up for an EcoTrove account, select a password and user name (“EcoTrove User
              ID”), and provide us with certain information or data, such as your contact information. You promise to
              provide us with accurate, complete, and updated registration information about yourself. You may not
              select as your EcoTrove User ID a name that you do not have the right to use, or another person’s name
              with the intent to impersonate that person. You may not transfer your account to anyone else without our
              prior written permission.
            </p>
            <p className="mt-4">
              You will only use the Services for your own internal, personal, non-commercial use, and not on behalf of
              or for the benefit of any third party, and only in a manner that complies with all laws that apply to you.
              You will comply with all laws that apply to you, your use of the Services, and your actions and omissions
              that relate to the Services. If your use of the Services is prohibited by applicable laws, then you aren’t
              authorized to use the Services. We can’t and won’t be responsible for your using the Services in a way
              that breaks the law.
            </p>
            <p className="mt-4">
              You will not share your EcoTrove User ID, account or password with anyone, and you must protect the
              security of your EcoTrove User ID, account, password and any other access tools or credentials. You’re
              responsible for any activity associated with your EcoTrove User ID and account.
            </p>
          </section>

          {/* Messaging Section */}
          <section id="messaging-section">
            <h2 className="text-2xl font-bold">What About Messaging?</h2>
            <p className="mt-4">
              As part of the Services, you may receive communications through the Services, including messages that
              EcoTrove sends you (for example, via email). When signing up for the Services, you will receive a welcome
              message and instructions on how to stop receiving messages.
            </p>
          </section>

          {/* Restrictions Section */}
          <section id="restrictions" className="mt-8">
            <h2 className="text-2xl font-bold">Are there restrictions in how I can use the Services?</h2>
            <p className="mt-4">
              You represent, warrant, and agree that you will not provide or contribute anything, including any Content
              (as that term is defined below), to the Services, or otherwise use or interact with the Services, in a
              manner that:
            </p>
            <ul className="list-disc pl-6 mt-4 space-y-2">
              <li>
                (a) infringes or violates the intellectual property rights or any other rights of anyone else (including
                EcoTrove);
              </li>
              <li>
                (b) violates any law or regulation, including, without limitation, any applicable export control laws,
                privacy laws, or any other purpose not reasonably intended by EcoTrove;
              </li>
              <li>
                (c) is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or
                otherwise objectionable;
              </li>
              <li>
                (d) jeopardizes the security of your EcoTrove User ID, account, or anyone else’s (such as allowing
                someone else to log in to the Services as you);
              </li>
              <li>
                (e) attempts, in any manner, to obtain the password, account, or other security information from any
                other user;
              </li>
              <li>
                (f) violates the security of any computer network or cracks any passwords or security encryption codes;
              </li>
              <li>
                (g) runs Maillist, Listserv, any form of auto-responder, or “spam” on the Services, or any processes
                that run or are activated while you are not logged into the Services, or that otherwise interfere with
                the proper working of the Services (including by placing an unreasonable load on the Services’
                infrastructure);
              </li>
              <li>
                (h) “crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or
                Content (through use of manual or automated means);
              </li>
              <li>(i) copies or stores any significant portion of the Content; or</li>
              <li>
                (j) decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas
                or information of or relating to the Services.
              </li>
            </ul>
            <p className="mt-4">
              A violation of any of the foregoing is grounds for termination of your right to use or access the
              Services.
            </p>
          </section>

          {/* Rights section */}
          <section id="service-rights">
            <h2 className="text-2xl font-bold">What are my rights in the Services?</h2>
            <p className="mt-4">
              The materials displayed or performed or available on or through the Services, including, but not limited
              to, text, graphics, data, articles, photos, images, illustrations and so forth (all of the foregoing, the
              “Content”) are protected by copyright and/or other intellectual property laws. You promise to abide by all
              copyright notices, trademark rules, information, and restrictions contained in any Content you access
              through the Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit,
              distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose
              any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way
              that violates someone else’s (including EcoTrove’s) rights.
            </p>
            <p className="mt-4">
              Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable
              and non-transferable license to use (i.e., to download and display locally) Content solely for purposes of
              using the Services. Use, reproduction, modification, distribution or storage of any Content for any
              purpose other than using the Services is expressly prohibited without prior written permission from us.
            </p>
            <p className="mt-4">
              You understand that EcoTrove owns the Services. You won’t modify, publish, transmit, participate in the
              transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works
              based on, or otherwise exploit any of the Services. The Services may allow you to copy or download certain
              Content, but please remember that even where these functionalities exist, all the restrictions in this
              section still apply.
            </p>
          </section>

          {/* Submissions and Licenses */}
          <section id="submissions-and-licenses">
            <h2 className="text-2xl font-bold">
              What about anything I contribute to the Services – do I have to grant any licenses to EcoTrove or to other
              users?
            </h2>
            <h2 className="text-2xl font-bold">User Submissions</h2>
            <p className="mt-4">
              Anything you post, upload, share, store, or otherwise provide through the Services is your “User
              Submission”. Some User Submissions may be viewable by other users. You are solely responsible for all User
              Submissions you contribute to the Services. You represent that all User Submissions submitted by you are
              accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations.
            </p>
            <p className="mt-4">
              Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable
              and non-transferable license to use (i.e., to download and display locally) Content solely for purposes of
              using the Services. Use, reproduction, modification, distribution or storage of any Content for any
              purpose other than using the Services is expressly prohibited without prior written permission from us.
            </p>
            <p className="mt-4">
              You agree that you will not post, upload, share, store, or otherwise provide through the Services any User
              Submissions that:
            </p>
            <ul>
              <li>
                (i) infringe any third party's copyrights or other rights (e.g., trademark, privacy rights, etc.);
              </li>
              <li>(ii) contain sexually explicit content or pornography;</li>
              <li>
                (iii) contain hateful, defamatory, or discriminatory content or incite hatred against any individual or
                group;
              </li>
              <li>(iv) exploit minors;</li>
              <li>(v) depict unlawful acts or extreme violence;</li>
              <li>(vi) depict animal cruelty or extreme violence towards animals;</li>
              <li>
                (vii) promote fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online
                gaming and gambling, cash gifting, work from home businesses, or any other dubious money-making
                ventures; or
              </li>
              <li>(viii) that violate any law.</li>
            </ul>
          </section>

          {/* Licenses */}
          <section id="licenses">
            <h2 className="text-2xl font-bold">Licenses</h2>
            <p className="mt-4">
              In order to display your User Submissions on the Services, to allow other users to enjoy them (where
              applicable), and to allow us to provide the Services to you (where applicable), you grant us certain
              rights in those User Submissions (see below for more information). Please note that all of the following
              licenses are subject to our Privacy Policy to the extent they relate to User Submissions that are also
              your personally-identifiable information.
            </p>
            <p className="mt-4">
              By submitting User Submissions through the Services, you hereby do and shall grant EcoTrove a worldwide,
              non-exclusive, perpetual, royalty-free, fully paid, sublicensable and transferable license to use, edit,
              modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and
              otherwise fully exploit the User Submissions in connection with this site, the Services and our (and our
              successors’ and assigns’) businesses, including without limitation for promoting and redistributing part
              or all of this site or the Services (and derivative works thereof) in any media formats and through any
              media channels (including, without limitation, third party websites and feeds), and including after your
              termination of your account or the Services.
            </p>
            <p className="mt-4">
              You also hereby do and shall grant each user of this site and/or the Services a non-exclusive, perpetual
              license to access your User Submissions through this site and/or the Services, and to use, edit, modify,
              reproduce, distribute, prepare derivative works of, display and perform such User Submissions, including
              after your termination of your account or the Services. For clarity, the foregoing license grants to us
              and our users do not affect your other ownership or license rights in your User Submissions, including the
              right to grant additional licenses to your User Submissions, unless otherwise agreed in writing. You
              represent and warrant that you have all rights to grant such licenses to us without infringement or
              violation of any third party rights, including without limitation, any privacy rights, publicity rights,
              copyrights, trademarks, contract rights, or any other intellectual property or proprietary rights.
            </p>
            <p className="mt-4">
              Finally, you understand and agree that EcoTrove, in performing the required technical steps to provide the
              Services to our users (including you), may need to make changes to your User Submissions to conform and
              adapt those User Submissions to the technical requirements of connection networks, devices, services, or
              media, and the foregoing licenses include the rights to do so.
            </p>
          </section>

          {/* Responsibility */}
          <section id="responsibility">
            <h2 className="text-2xl font-bold">Licenses</h2>
            <p className="mt-4">
              Any information or Content publicly posted or privately transmitted through the Services is the sole
              responsibility of the person from whom such Content originated, and you access all such information and
              Content at your own risk, and we aren’t liable for any errors or omissions in that information or Content
              or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to
              take any action regarding how you may interpret and use the Content or what actions you may take as a
              result of having been exposed to the Content, and you hereby release us from all liability for you having
              acquired or not acquired Content through the Services. We can’t guarantee the identity of any users with
              whom you interact in using the Services and are not responsible for which users gain access to the
              Services.
            </p>
            <p className="mt-4">
              You are responsible for all Content you contribute, in any manner, to the Services, and you represent and
              warrant you have all rights necessary to do so, in the manner in which you contribute it.
            </p>
            <p className="mt-4">
              The Services may contain links or connections to third-party websites or services that are not owned or
              controlled by EcoTrove, including but not limited to your Energy Provider. When you access third-party
              websites or use third-party services, you accept that there are risks in doing so, and that EcoTrove is
              not responsible for such risks. In order to provide the Services, EcoTrove relies on the accuracy and
              completeness of the information and Content you and your Energy Provider provide. You acknowledge and
              agree that EcoTrove cannot and will not be responsible for any issues that arise between you and your
              Energy Provider and are based on any inaccurate information or Content either you or your Energy Provider
              provide us.
            </p>
            <p className="mt-4">
              EcoTrove has no control over, and assumes no responsibility for, the content, accuracy, privacy policies,
              or practices of or opinions expressed in any third-party websites or by any third party that you interact
              with through the Services, such as your Energy Provider. In addition, EcoTrove will not and cannot
              monitor, verify, censor or edit the content of any third-party site or service. We encourage you to be
              aware when you leave the Services and to read the terms and conditions and privacy policy of each
              third-party website or service that you visit or utilize. By using the Services, you release and hold us
              harmless from any and all liability arising from your use of any third-party website or service.
            </p>
            <p className="mt-4">
              Your interactions with your Energy Provider, including your payment obligations and delivery of goods or
              services, and any other terms, conditions, warranties or representations associated with such dealings,
              are solely between you and such organizations and/or individuals. You agree that EcoTrove shall not be
              responsible or liable for any loss or damage of any sort incurred as the result of any such dealings, to
              the extent such loss or damage did not result directly and solely from the actions of EcoTrove. To the
              extent you have any outstanding balance or payments due to your Energy Provider at the time you begin your
              Subscription, EcoTrove assumes no liability for such outstanding balances.
            </p>
            <p className="mt-4">
              If there is a dispute between participants on this site or Services, or between users and any third party,
              including your Energy Provider, you agree that EcoTrove is under no obligation to become involved. In the
              event that you have a dispute with one or more other users, you release EcoTrove, its directors, officers,
              employees, agents, and successors from claims, demands, and damages of every kind or nature, known or
              unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such
              disputes and/or our Services. You shall and hereby do waive California Civil Code Section 1542 or any
              similar law of any jurisdiction, which says in substance: “A general release does not extend to claims
              that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of
              executing the release and that, if known by him or her, would have materially affected his or her
              settlement with the debtor or released party.”
            </p>
          </section>

          {/* Costs */}
          <section id="cost-of-services">
            <h2 className="text-2xl font-bold">Do the Services cost anything?</h2>
            <p className="mt-4">
              The Services are free to access but we may charge a fee for certain subscriptions made while using the
              Services (the “Paid Services”). If you are using a free version of the Services, we will notify you before
              any Services you are then using begin carrying a fee, and if you wish to continue using such Services, you
              must pay all applicable fees for such Services.
            </p>
            <ul className="space-y-4">
              <li>
                <strong>a. Paid Services.</strong> If you have a Subscription, such Subscription is a Paid Service. The
                Subscriptions are subject to payment in accordance with the payment terms presented to you in the
                process of signing up for a Subscription, including your Quote, which payment terms are deemed part of
                these Terms.
              </li>
              <li>
                <strong>b. Billing.</strong> We use a third-party payment processor (the “Payment Processor”) to bill
                you through a payment account linked to your account on the Services (your “Billing Account”) for use of
                the Paid Services. The processing of payments will be subject to the terms, conditions and privacy
                policies of the Payment Processor in addition to these Terms. Currently, we use Stripe, Inc. as our
                Payment Processor. You can access Stripe’s Terms of Service at{" "}
                <a href="https://stripe.com/us/checkout/legal">https://stripe.com/us/checkout/legal</a> and their
                Privacy Policy at <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>. We are not
                responsible for any error by, or other acts or omissions of, the Payment Processor. By choosing to use
                Paid Services, you agree to pay us, through the Payment Processor, all charges at the prices then in
                effect for any use of such Paid Services in accordance with the applicable payment terms, and you
                authorize us, through the Payment Processor, to charge your chosen payment provider (your “Payment
                Method”). You agree to make payment using that selected Payment Method. We reserve the right to correct
                any errors or mistakes that the Payment Processor makes even if it has already requested or received
                payment.
              </li>
              <li>
                <strong>c. Payment Method.</strong> The terms of your payment will be based on your Payment Method and
                may be determined by agreements between you and the financial institution, credit card issuer or other
                provider of your chosen Payment Method. If we, through the Payment Processor, do not receive payment
                from you, you agree to pay all amounts due on your Billing Account upon demand.
              </li>
              <li>
                <strong>d. Monthly Fees and Recurring Billing.</strong> If you have a Subscription, your Subscription
                will include recurring monthly charges as stated in your Quote, and agreed to by you (your “Monthly
                Fee”). EcoTrove will bill you Monthly Fees to help enable us to pay your Energy Provider on time. By
                signing up for a Subscription with us, you acknowledge that such Services include such recurring Monthly
                Fees and you accept responsibility for all recurring charges prior to cancellation.{" "}
                <strong>
                  WE MAY SUBMIT SUCH PERIODIC CHARGES WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR
                  NOTICE (RECEIPT OF WHICH IS CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO
                  CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD
                  ACT. TO TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD, GO TO{" "}
                  <a href="https://ecotrove.com/manage-account">https://ecotrove.com/manage-account</a> OR EMAIL US AT
                  INFO@ECOTROVE.COM.
                </strong>
              </li>
              <li>
                <strong>e. Current Information Required.</strong> YOU ALSO ACKNOWLEDGE AND AGREE THAT, IN ORDER FOR US
                TO PROVIDE YOUR SUBSCRIPTION YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR
                BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE
                AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE),
                AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR
                LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED
                DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE AT ACCOUNT
                SETTINGS ( <a href="https://ecotrove.com/manage-account">https://ecotrove.com/manage-account</a>). IF
                YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR
                ANY USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS SET
                FORTH ABOVE.
              </li>
              <li>
                <strong>f. Changes to Monthly Fees.</strong> Your Monthly Fee is subject to change, including, for
                example, if your energy usage differs from the energy usage data originally provided to us or if your
                Energy Provider changes the fees it charges. In the event of a change in your Monthly Fee, you have the
                right to receive, and we shall provide, a new Quote with notice of the amount to be charged or credited
                and the date of the charge or credit before the scheduled date of the transaction. Any agreement you
                have with your payment provider will govern your use of your Payment Method. You agree that we may
                accumulate charges incurred and submit them as one or more aggregate charges during or at the end of
                each billing cycle.
              </li>
              <li>
                <strong id="auto-renewal">g. Auto-Renewal for Paid Services.</strong> Unless you opt out of
                auto-renewal, which can be done by responding to the notice EcoTrove will issue you before the end of
                your 12-month subscription, or through your account settings ({" "}
                <a href="https://ecotrove.com/manage-account">https://ecotrove.com/manage-account</a>), any Paid
                Services you have signed up for will be automatically extended for successive annual renewal periods, at
                your then current Monthly Fee rate. To change or resign your Paid Services at any time, go to account
                settings. If you terminate a Paid Service, EcoTrove will cease providing the Services, and in the case
                of a Subscription, stop making payments on your behalf to your Energy Provider. At such time, it is your
                responsibility to make payments to your Energy Provider directly.{" "}
                <strong>
                  IF YOU DO NOT WANT TO CONTINUE TO BE CHARGED ON A RECURRING MONTHLY BASIS, YOU MUST CANCEL THE
                  APPLICABLE PAID SERVICE THROUGH YOUR ACCOUNT SETTINGS OR TERMINATE YOUR ECOTROVE ACCOUNT BEFORE THE
                  END OF THE RECURRING TERM. PAID SERVICES CANNOT BE TERMINATED BEFORE THE END OF THE PERIOD FOR WHICH
                  YOU HAVE ALREADY PAID, AND EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS, ECOTROVE WILL NOT REFUND ANY
                  FEES THAT YOU HAVE ALREADY PAID.
                </strong>
              </li>
              <li>
                <strong>h. Reaffirmation of Authorization.</strong> Your non-termination or continued use of a Paid
                Service reaffirms that we are authorized to charge your Payment Method for that Paid Service. We may
                submit those charges for payment and you will be responsible for such charges. This does not waive our
                right to seek payment directly from you. Your charges may be payable in advance, in arrears, per usage,
                or as otherwise described when you initially selected to use the Paid Service.
              </li>
              <li>
                <strong>i. Late or Missing Payments.</strong> If you have a Subscription, you acknowledge and agree that
                our ability to pay your Energy Provider for the services they provide you is dependent on you paying
                your Monthly Fee on time. We are not responsible for any penalties resulting from late payments to the
                extent such delay was caused by your failure to pay us the Monthly Fee.
              </li>
            </ul>
          </section>

          {/* Stop using services */}
          <section id="stop-using-services">
            <h2 className="text-2xl font-bold">What if I want to stop using the Services?</h2>
            <p className="mt-4">
              You’re free to do that at any time by contacting us at{" "}
              <a href="mailto:info@ecotrove.com" className="text-primary underline">
                info@ecotrove.com
              </a>{" "}
              or via our website at{" "}
              <a href="https://ecotrove.com/manage-account" className="text-primary underline">
                https://ecotrove.com/manage-account
              </a>
              . ; please refer to our Privacy Policy, as well as the licenses above, to understand how we treat
              information you provide to us after you have stopped using our Services.
            </p>
            <p className="mt-4">
              EcoTrove is also free to terminate (or suspend access to) your use of the Services or your account for any
              reason in our discretion, including your breach of these Terms. EcoTrove has the sole right to decide
              whether you are in violation of any of the restrictions set forth in these Terms.
            </p>
            <p className="mt-4">
              Account termination may result in destruction of any Content associated with your account, so keep that in
              mind before you decide to terminate your account.
            </p>
            <p className="mt-4">
              If you have deleted your account or terminated your Subscription by mistake, contact us immediately at{" "}
              <a href="mailto:info@ecotrove.com" className="text-primary underline">
                info@ecotrove.com
              </a>{" "}
              – we will try to help, but unfortunately, we can’t promise that we can recover or restore anything.
            </p>
            <p className="mt-4">
              Provisions that, by their nature, should survive termination of these Terms shall survive termination. By
              way of example, all of the following will survive termination: any obligation you have to pay us or
              indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property
              rights, and terms regarding disputes between us, including without limitation the arbitration agreement.
            </p>
          </section>

          {/* Referrals */}
          <section id="referrals">
            <h2 className="text-2xl font-bold">Can I refer other users?</h2>
            <p className="mt-4">
              From time to time EcoTrove may offer rewards or incentives for referring others to the Services. For
              details of any current referral offers, please see our referral page located at:{" "}
              <a href="https://ecotrove.com/save-more" className="text-primary underline">
                https://ecotrove.com/save-more
              </a>
              . The referring user (“Referrer”) may refer individuals or entities who are neither current customers of
              EcoTrove nor registered users of the Services (“Referee”). A registered user is a person or entity that
              already has an existing account with EcoTrove. There is no limit to the number of referrals that Referrer
              can make, nor the cumulative rewards or incentives that the Referrer may receive through such special
              offer, unless otherwise indicated. Referrer will receive the stated reward or incentive for each Referee
              sent by the Referrer that completes the required action described in that specific offer (such as signing
              up for an account or making a purchase). All Referees must be first-time recipients of the offer, and
              multiple referrals to the same individual or entity will be disregarded. EcoTrove reserves the right to
              modify or terminate any special offers at any time and to revoke from Referrer and Referee the special
              offer at EcoTrove’s discretion for any reason or for no reason whatsoever. If EcoTrove determines that
              Referrer or Referee is attempting to obtain unfair advantage or otherwise violate the terms or spirit of
              such special offer, EcoTrove reserves the right to (a) revoke any rewards or incentives issued to either
              Referrer or Referee and/or (b) charge the Referrer or Referee for any rewards or incentives (1) used by
              Referrer or Referee prior to such revocation or (2) issued by EcoTrove to any ineligible Referrer or
              Referee. All special offers are subject to any other terms, conditions and restrictions set forth on the
              Services or presented in connection with the special offer.
            </p>
          </section>

          {/* Additional Information */}
          <section id="additional-information">
            <h2 className="text-2xl font-bold">What else do I need to know?</h2>
            <p className="mt-4">
              <p className="mt-4">
                <strong>Warranty Disclaimer.</strong> EcoTrove and its licensors, suppliers, partners, parent,
                subsidiaries or affiliated entities, and each of their respective officers, directors, members,
                employees, consultants, contract employees, representatives and agents, and each of their respective
                successors and assigns (EcoTrove and all such parties together, the “EcoTrove Parties”) make no
                representations or warranties concerning the Services, including without limitation regarding any
                Content contained in or accessed through the Services, and the EcoTrove Parties will not be responsible
                or liable for the accuracy, copyright compliance, legality, or decency of material contained in or
                accessed through the Services or any claims, actions, suits procedures, costs, expenses, damages or
                liabilities arising out of use of, or in any way related to your participation in, the Services. The
                EcoTrove Parties make no representations or warranties regarding suggestions or recommendations of
                services or products offered or purchased through or in connection with the Services including, without
                limitation, any products or services that are offered by your Energy Provider. THE SERVICES AND CONTENT
                ARE PROVIDED BY ECOTROVE (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF
                ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE
                UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS,
                SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. YOU ACKNOWLEDGE AND AGREE THAT WE CANNOT AND WILL NOT BE
                RESPONSIBLE FOR THE PROVISION OF ELECTRICITY OR ANY OTHER ENERGY SERVICES, OR FOR THE RELIABILITY OF ANY
                ENERGY PROVIDER.
              </p>
              <p className="mt-4">
                <strong id="limitations-of-liability">Limitation of Liability.</strong> TO THE FULLEST EXTENT ALLOWED BY
                APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT,
                CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE ECOTROVE PARTIES BE LIABLE TO YOU OR TO ANY
                OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND,
                INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK
                STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR
                TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100) DOLLARS
                OR (II) THE AMOUNTS PAID AND/OR PAYABLE BY YOU TO ECOTROVE IN CONNECTION WITH THE SERVICES IN THE SIX
                (6) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME
                STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES,
                SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
              </p>
              <p className="mt-4">
                <strong>Indemnity.</strong> You agree to indemnify and hold the EcoTrove Parties harmless from and
                against any and all claims, liabilities, damages (actual and consequential), losses and expenses
                (including attorneys’ fees) arising from or in any way related to any claims relating to (a) your use of
                the Services (including any actions taken by a third party using your account), and (b) your violation
                of these Terms. In the event of such a claim, suit, or action (“Claim”), we will attempt to provide
                notice of the Claim to the contact information we have for your account (provided that failure to
                deliver such notice shall not eliminate or reduce your indemnification obligations hereunder).
              </p>
              <p className="mt-4">
                <strong>Assignment.</strong> You may not assign, delegate or transfer these Terms or your rights or
                obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without
                EcoTrove’s prior written consent. We may transfer, assign, or delegate these Terms and our rights and
                obligations without consent.
              </p>
              <p className="mt-4">
                <strong>Choice of Law.</strong> These Terms are governed by and will be construed under the Federal
                Arbitration Act, applicable federal law, and the laws of the State of California, without regard to the
                conflicts of laws provisions thereof.
              </p>
              <p className="mt-4">
                <strong id="arbitration-agreement">Arbitration Agreement.</strong> Please read the following ARBITRATION
                AGREEMENT carefully because it requires you to arbitrate certain disputes and claims with EcoTrove and
                limits the manner in which you can seek relief from EcoTrove. Both you and EcoTrove acknowledge and
                agree that for the purposes of any dispute arising out of or relating to the subject matter of these
                Terms, EcoTrove’s officers, directors, employees and independent contractors (“Personnel”) are
                third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel will
                have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the
                third-party beneficiary hereof.
              </p>
              <p className="mt-4">
                (a) <strong>Arbitration Rules; Applicability of Arbitration Agreement.</strong> The parties shall use
                their best efforts to settle any dispute, claim, question, or disagreement arising out of or relating to
                the subject matter of these Terms directly through good-faith negotiations, which shall be a
                precondition to either party initiating arbitration. If such negotiations do not resolve the dispute, it
                shall be finally settled by binding arbitration in San Francisco, California. The arbitration will
                proceed in the English language, in accordance with the JAMS Streamlined Arbitration Rules and
                Procedures (the “Rules”) then in effect, by one commercial arbitrator with substantial experience in
                resolving intellectual property and commercial contract disputes. The arbitrator shall be selected from
                the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered
                by such arbitrator may be entered in any court of competent jurisdiction.
              </p>
              <p className="mt-4">
                (b) <strong>Costs of Arbitration.</strong> The Rules will govern payment of all arbitration fees.
                EcoTrove will pay all arbitration fees for claims less than seventy-five thousand ($75,000) dollars.
                EcoTrove will not seek its attorneys’ fees and costs in arbitration unless the arbitrator determines
                that your claim is frivolous.
              </p>
              <p className="mt-4">
                (c) <strong>Small Claims Court; Infringement.</strong> Either you or EcoTrove may assert claims, if they
                qualify, in small claims court in San Francisco, California or any United States county where you live
                or work. Furthermore, notwithstanding the foregoing obligation to arbitrate disputes, each party shall
                have the right to pursue injunctive or other equitable relief at any time, from any court of competent
                jurisdiction, to prevent the actual or threatened infringement, misappropriation or violation of a
                party's copyrights, trademarks, trade secrets, patents or other intellectual property rights.
              </p>
              <p className="mt-4">
                (d) <strong>Waiver of Jury Trial.</strong> YOU AND ECOTROVE WAIVE ANY CONSTITUTIONAL AND STATUTORY
                RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and EcoTrove are instead
                choosing to have claims and disputes resolved by arbitration. Arbitration procedures are typically more
                limited, more efficient, and less costly than rules applicable in court and are subject to very limited
                review by a court. In any litigation between you and EcoTrove over whether to vacate or enforce an
                arbitration award, YOU AND ECOTROVE WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the
                dispute be resolved by a judge.
              </p>
              <p className="mt-4">
                (e) <strong>Waiver of Class or Consolidated Actions.</strong> ALL CLAIMS AND DISPUTES WITHIN THE SCOPE
                OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS
                BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR
                CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or consolidated
                actions is deemed invalid or unenforceable, neither you nor EcoTrove is entitled to arbitration; instead
                all claims and disputes will be resolved in a court as set forth in (g) below.
              </p>
              <p className="mt-4">
                (f) <strong>Opt-out.</strong> You have the right to opt out of the provisions of this Section by sending
                written notice of your decision to opt out to the following address: 2 Embarcadero Center, Floor 8, San
                Francisco, CA, 94111, USA postmarked within thirty (30) days of first accepting these Terms. You must
                include (i) your name and residence address, (ii) the email address and/or telephone number associated
                with your account, and (iii) a clear statement that you want to opt out of these Terms’ arbitration
                agreement.
              </p>
              <p className="mt-4">
                (g) <strong>Exclusive Venue.</strong> If you send the opt-out notice in (f), and/or in any circumstances
                where the foregoing arbitration agreement permits either you or EcoTrove to litigate any dispute arising
                out of or relating to the subject matter of these Terms in court, then the foregoing arbitration
                agreement will not apply to either party, and both you and EcoTrove agree that any judicial proceeding
                (other than small claims actions) will be brought in the state or federal courts located in,
                respectively, San Francisco County, California, or the federal district in which that county falls.
              </p>
              <p className="mt-4">
                (h) <strong>Severability.</strong> If the prohibition against class actions and other claims brought on
                behalf of third parties contained above is found to be unenforceable, then all of the preceding language
                in this Arbitration Agreement section will be null and void. This arbitration agreement will survive the
                termination of your relationship with EcoTrove.
              </p>
              <p className="mt-4">
                <strong>Miscellaneous.</strong> You will be responsible for paying, withholding, filing, and reporting
                all taxes, duties, and other governmental assessments associated with your activity in connection with
                the Services, provided that the EcoTrove may, in its sole discretion, do any of the foregoing on your
                behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right
                herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms are
                found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum
                extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable.
                You and EcoTrove agree that these Terms are the complete and exclusive statement of the mutual
                understanding between you and EcoTrove, and that these Terms supersede and cancel all previous written
                and oral agreements, communications and other understandings relating to the subject matter of these
                Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture
                of EcoTrove, and you do not have any authority of any kind to bind EcoTrove in any respect whatsoever.
              </p>
              <p className="mt-4">
                Except as expressly set forth in the section above regarding the arbitration agreement, you and EcoTrove
                agree there are no third-party beneficiaries intended under these Terms.
              </p>
            </p>
          </section>

          {/* Contact Us */}
          <section id="contact-us">
            <h2 className="text-2xl font-bold">Contact Us</h2>
            <ul className="list-disc pl-5 mt-4">
              <li>
                Email:{" "}
                <a href="mailto:info@ecotrove.com" className="text-primary underline">
                  info@ecotrove.com
                </a>
              </li>
              <li>Phone: +1 (415) 237 - 1740</li>
              <li>Address: 2 Embarcadero Center, Floor 8, San Francisco, CA 94111, USA</li>
            </ul>
          </section>
        </div>
      </div>

      {/* Footer */}
      <RDCFooter />
    </div>
  );
};
