import { useNavigate } from "react-router-dom";
import { CheckAvailabilityHeroFieldValues, RDCCheckAvailabilityForm } from "./RDCCheckAvailabilityForm";
import { GET_QUOTE_ROUTE } from "../../constants";
import { SubmitHandler } from "react-hook-form";
import mixpanel from "mixpanel-browser";

/**
 * Form component that prompts a user to check their ZIP code availability at the end of scrolling.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCFinishedScrollingForm: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit: SubmitHandler<CheckAvailabilityHeroFieldValues> = (data) => {
    mixpanel.track("Check availability from finished scrolling section");
    navigate(`${GET_QUOTE_ROUTE}?zipCode=${encodeURIComponent(data.zipCode)}`);
  };

  return (
    <div className="flex flex-col w-full items-center bg-accent text-center px-content-mobile lg:px-content py-content-mobile lg:py-8">
      <h1 className="leading-none">
        Finished scrolling?
        <br />
        Start saving today.
      </h1>
      <p className="lg:w-1/3 mt-4">
        Allow EcoTrove to become your Energy Champion to make your power bills cheaper, greener, and more predictable.
      </p>
      <div className="lg:w-1/3 mt-12">
        <RDCCheckAvailabilityForm data-tests={{ zipCode: "zipcode-finished-scrolling" }} onSubmit={handleSubmit} />
      </div>
    </div>
  );
};
