import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

export type AlertBannerType = "success" | "warning" | "error";

const TYPE_CONFIG: Record<AlertBannerType, { daisyAlertType: string; icon: JSX.Element }> = {
  success: { daisyAlertType: "daisy-alert-success", icon: <CheckCircleIcon className="hero-icon" /> },
  warning: { daisyAlertType: "daisy-alert-warning", icon: <ExclamationCircleIcon className="hero-icon" /> },
  error: { daisyAlertType: "daisy-alert-error", icon: <ExclamationCircleIcon className="hero-icon" /> },
};

/**
 * Props to render a `RDCAlertBanner` component with.
 */
export interface RDCAlertBannerProps {
  type: AlertBannerType;
  message: string;
  onClickClose: () => void;
}

/**
 * Page banner that displays a message.
 * @param props the props to render the component with.
 * @returns a React `FunctionComponent`.
 */
export const RDCAlertBanner: React.FC<RDCAlertBannerProps> = (props) => {
  const { type, message, onClickClose } = props;
  const { daisyAlertType, icon } = TYPE_CONFIG[type];

  return (
    <div className={`daisy-alert ${daisyAlertType} flex w-full text-left rounded-none top-0 left-0 z-50`}>
      {icon}
      <p className="grow">{message}</p>
      <button
        className="daisy-btn daisy-btn-sm daisy-btn-ghost daisy-btn-circle"
        onClick={onClickClose}
        aria-label="close">
        <XMarkIcon className="hero-icon hero-icon-sm" />
      </button>
    </div>
  );
};
