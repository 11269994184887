import mixpanel from "mixpanel-browser";
import React from "react";

/**
 * Props to pass to a `RDCCollapseList`.
 */
export interface RDCCollapseListProps {
  itemsDescription: string;
  items: { text: string; subText: string }[];
}

/**
 * List of text/subText items that can be collapsed.
 * @param props
 * @returns
 */
export const RDCCollapseList: React.FC<RDCCollapseListProps> = (props) => {
  const { itemsDescription, items } = props;

  /**
   * Handler that tracks each item click on mixpanel.
   */
  const handleClick = (i: number) => {
    mixpanel.track(`Expand ${itemsDescription} #${i}`);
  };

  return (
    <fieldset className="space-y-5 w-full">
      {items.map(({ text, subText }, i) => (
        <div className="daisy-collapse daisy-collapse-arrow border border-neutral-200" key={i}>
          <input type="radio" name="FAQ" aria-label={`${itemsDescription} #${i}`} onClick={() => handleClick(i)} />
          <p className="daisy-collapse-title font-semibold">{text}</p>
          <p className="daisy-collapse-content">{subText} </p>
        </div>
      ))}
    </fieldset>
  );
};
