import {
  ChatBubbleBottomCenterIcon,
  ChatBubbleBottomCenterTextIcon,
  EnvelopeIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
  TagIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import mixpanel from "mixpanel-browser";
import { SubmitHandler, useForm } from "react-hook-form";
import { supabase } from "../../supabaseClient";
import { useAlertStore } from "../stores";
import { ECOTROVE_EMAIL_INFO, EMAIL_REGEX, FULL_NAME_REGEX } from "../../constants";
import { Utils } from "../../utils";
import { RDCValidationError } from "./RDCValidationError";
import { useEffect } from "react";

const CONTACT_TYPES = ["problem", "question", "feedback"] as const;
type ContactType = (typeof CONTACT_TYPES)[number];

interface ContactUsForm {
  type: ContactType;
  name: string;
  email: string;
  message: string;
}

const CONTACT_TYPE_ICONS: Record<ContactType, JSX.Element> = {
  problem: <ExclamationTriangleIcon className="hero-icon hero-icon-sm" />,
  question: <QuestionMarkCircleIcon className="hero-icon hero-icon-sm" />,
  feedback: <ChatBubbleBottomCenterIcon className="hero-icon hero-icon-sm" />,
};

/**
 * Props to pass to a `RDCContactUsModal`.
 */
export interface RDCContactUsModalProps {
  modalId: string;
  onSuccess?: () => void;
  name?: string;
  email?: string;
}

/**
 * Modal that contains a form which allows a user to send us a support email.
 */
export const RDCContactUsModal: React.FC<RDCContactUsModalProps> = (props) => {
  const { modalId, onSuccess, name, email } = props;
  const { setSuccessAlert, setErrorAlert } = useAlertStore();
  const contactUsForm = useForm<ContactUsForm>({
    defaultValues: { type: CONTACT_TYPES[0] },
  });

  useEffect(() => {
    contactUsForm.setValue("name", name ?? "");
    contactUsForm.setValue("email", email ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, email]);

  /**
   * Handler for contact us submissions.
   */
  const handleContactUs: SubmitHandler<ContactUsForm> = async (data) => {
    try {
      mixpanel.track(`${data.type} submitted from contact us modal ${modalId}`);
      const { error } = await supabase.from("support-messages").insert(data);
      if (error) throw error;
      contactUsForm.reset();
      setSuccessAlert("Thank you for reaching out. We have received your message and will be in touch soon.");
      onSuccess?.();
    } catch (error) {
      setErrorAlert(
        `An error occurred while sending your message. If this issue continues, please email us at ${ECOTROVE_EMAIL_INFO}`
      );
    } finally {
      Utils.closeDialogModal(modalId);
    }
  };

  return (
    <dialog id={modalId} className="daisy-modal p-content-mobile lg:p-content">
      <div className="daisy-modal-box flex flex-col gap-6 max-w-none w-full lg:w-2/3">
        {/* Header */}
        <div className="space-y-default">
          <h2 className="text-xl">Contact Us</h2>
          <p className="text-neutral-content">Please fill out the form below.</p>
        </div>
        {/* Form */}
        <form
          className="grow flex flex-col gap-default items-start"
          onSubmit={contactUsForm.handleSubmit((data) =>
            handleContactUs({ ...data, email: data.email.toLowerCase() })
          )}>
          <div className="grow flex flex-col gap-4 w-full">
            {/* Type */}
            <div className="flex flex-col gap-default w-fit">
              <p className="flex items-center gap-default text-neutral-content">
                <TagIcon className="hero-icon hero-icon-sm" />
                Type
              </p>
              <div className="flex flex-wrap gap-4">
                {CONTACT_TYPES.map((type, i) => (
                  <button
                    key={i}
                    className={`daisy-btn daisy-btn-neutral ${
                      contactUsForm.watch("type") === type ? "daisy-btn-active" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      contactUsForm.setValue("type", type);
                    }}>
                    <p className="flex items-center gap-default">
                      {CONTACT_TYPE_ICONS[type]}
                      {Utils.capitalize(type)}
                    </p>
                  </button>
                ))}
              </div>
            </div>
            {/* Full name */}
            <div className="flex flex-col gap-default w-full">
              <p id="full-name-label" className="flex items-center gap-default text-neutral-content">
                <UserCircleIcon className="hero-icon hero-icon-sm" />
                Full Name
              </p>
              <input
                aria-labelledby="full-name-label"
                className="w-full daisy-input daisy-input-bordered aria-invalid:daisy-input-error aria-invalid:text-error"
                type="text"
                placeholder="Enter your full name"
                aria-invalid={Boolean(contactUsForm.formState.errors.name)}
                {...contactUsForm.register("name", {
                  required: true,
                  validate: {
                    matches: (value) => FULL_NAME_REGEX.test(value) || "Please enter your full name",
                  },
                })}
              />
              <RDCValidationError error={contactUsForm.formState.errors.name?.message ?? ""} />
            </div>
            {/* Email */}
            <div className="flex flex-col gap-default w-full">
              <p id="email-label" className="flex items-center gap-default text-neutral-content">
                <EnvelopeIcon className="hero-icon hero-icon-sm" />
                Email
              </p>
              <input
                aria-labelledby="email-label"
                className="w-full daisy-input daisy-input-bordered aria-invalid:daisy-input-error aria-invalid:text-error"
                type="text"
                placeholder="Enter your email"
                aria-invalid={Boolean(contactUsForm.formState.errors.email)}
                {...contactUsForm.register("email", {
                  required: true,
                  validate: {
                    matches: (value) => EMAIL_REGEX.test(value) || "Please enter a valid email",
                  },
                })}
              />
              <RDCValidationError error={contactUsForm.formState.errors.email?.message ?? ""} />
            </div>
            {/* Message */}
            <div className="flex flex-col gap-default w-full">
              <p id="message-label" className="flex items-center gap-default text-neutral-content">
                <ChatBubbleBottomCenterTextIcon className="hero-icon hero-icon-sm" />
                Message
              </p>
              <textarea
                aria-labelledby="message-label"
                className="daisy-textarea daisy-textarea-bordered aria-invalid:daisy-input-error aria-invalid:text-error"
                placeholder="Enter your message"
                aria-invalid={Boolean(contactUsForm.formState.errors.message)}
                {...contactUsForm.register("message", { required: true })}
              />
              <RDCValidationError error={contactUsForm.formState.errors.message?.message ?? ""} />
            </div>
          </div>
          <div className="daisy-modal-action flex flex-col lg:flex-row w-full items-center justify-between gap-4">
            <p className="text-neutral-content order-last lg:order-first">
              Or email us at{" "}
              <a className="daisy-link daisy-link-hover" href={`mailto:${ECOTROVE_EMAIL_INFO}`}>
                {ECOTROVE_EMAIL_INFO}
              </a>
            </p>
            <div className="flex flex-col lg:flex-row gap-default w-full lg:w-auto">
              <button className="daisy-btn daisy-btn-primary" type="submit">
                Send
              </button>
              <button
                className="daisy-btn daisy-btn-neutral"
                onClick={(e) => {
                  e.preventDefault();
                  Utils.closeDialogModal(modalId);
                }}>
                Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </dialog>
  );
};
