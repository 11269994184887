import React from "react";

/**
 * Props to pass to a `RDCExpandableTable`.
 */
export interface RDCExpandableTableProps {
  modalId: string;
  headers: string[];
  children: JSX.Element[];
  minRows: number;
  disabled?: boolean;
}

/**
 * Table component that can be expanded.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCExpandableTable: React.FC<RDCExpandableTableProps> = (props) => {
  const { modalId, headers, children, minRows, disabled } = props;

  /**
   * Handler for expand table button.
   */
  const handleExpandTable = () => {
    // Safe cast: we know this element to be a dialog element
    (document.getElementById(modalId) as HTMLDialogElement).showModal();
  };

  /**
   * Helper method that formats the data into a table element.
   * @param props the props to render the table with
   * @returns a table element.
   */
  const getTable = (props: { minRows: boolean }): JSX.Element => {
    const childrenToShow = !props.minRows ? children : children.filter((_, i) => i < minRows);
    return (
      <table className="table-auto w-full">
        <thead>
          <tr className="text-sm text-neutral-content border-b border-neutral-200">
            {headers.map((header, i) => (
              <th key={i} className="text-start">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{childrenToShow}</tbody>
      </table>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {/* Minimized table */}
      {getTable({ minRows: true })}
      <button
        className="daisy-btn daisy-btn-neutral daisy-btn-sm w-fit"
        onClick={handleExpandTable}
        disabled={disabled}>
        See all
      </button>

      {/* Expanded table modal */}
      <dialog id={modalId} className="daisy-modal p-content-mobile lg:p-content">
        <div className="daisy-modal-box flex flex-col">
          <div className="max-h-80 overflow-scroll">{getTable({ minRows: false })}</div>
          <div className="daisy-modal-action">
            <form method="dialog">
              <button className="daisy-btn daisy-btn-primary">Close</button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
};
