import { useEffect, useState } from "react";
import {
  AGREE_TO_TERMS_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  EMAIL_REGEX,
  UPDATE_PASSWORD_ROUTE,
  USER_DASHBOARD_ROUTE,
  USER_QUESTIONS_ROUTE,
} from "../../constants";
import { SubmitHandler, useForm } from "react-hook-form";
import { supabase } from "../../supabaseClient";
import { RDCCaptcha, RDCCardWithBackground, RDCValidationError } from "../components";
import { useNavigate } from "react-router-dom";
import { EnvelopeIcon, KeyIcon } from "@heroicons/react/24/outline";
import mixpanel from "mixpanel-browser";
import { useAlertStore } from "../stores";
import { Helmet } from "react-helmet";

interface LoginForm {
  email: string;
  password: string;
}

interface ResetPasswordForm {
  email: string;
}

/**
 * Form page that allows a user to log in.
 * @returns a React component.
 */
export const RDCLoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { setSuccessAlert, setErrorAlert } = useAlertStore();
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const [captchaToken, setCaptchaToken] = useState<string>("");
  const [captchaInvalid, setCaptchaInvalid] = useState<boolean>(false);
  const [captchaKey, setCaptchaKey] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [resetSubmitted, setResetSubmitted] = useState<boolean>(false);

  const login = useForm<LoginForm>();
  const resetPassword = useForm<ResetPasswordForm>();

  useEffect(() => {
    mixpanel.track("login");
  }, []);

  useEffect(() => {
    checkLoggedIn();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reset the captcha invalid message when switching between log in/reset password
  useEffect(() => {
    setCaptchaInvalid(false);
  }, [showForgotPassword]);

  /**
   * Helper method that checks if a user is already logged in, and redirects them if so.
   */
  const checkLoggedIn = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (user) navigate(USER_DASHBOARD_ROUTE);
  };

  /**
   * Handler for reset password events.
   */
  const handleResetPassword: SubmitHandler<ResetPasswordForm> = async ({ email }) => {
    setLoading(true);
    try {
      // Check captcha
      if (!captchaToken) {
        setCaptchaInvalid(true);
        return;
      }
      setCaptchaInvalid(false);

      // Initiate password reset process
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        // redirectTo: `http://localhost:3001${UPDATE_PASSWORD_ROUTE}`,
        redirectTo: `https://ecotrove.com${UPDATE_PASSWORD_ROUTE}`,
        captchaToken,
      });
      if (error) {
        throw error;
      }
      setSuccessAlert("Password reset email has been sent. Please check your inbox.");
      setResetSubmitted(true);
    } catch (error) {
      setErrorAlert("An error occurred while attempting to reset password. Please try again later.");
      return;
    } finally {
      // Increment captchaKey to force the captcha token to refresh
      setCaptchaKey((prev) => prev + 1);
      setLoading(false);
    }
  };

  /**
   * Handler for log in events.
   * @param data the data to login in with.
   */
  const handleLogin: SubmitHandler<LoginForm> = async ({ email, password }) => {
    setLoading(true);
    try {
      // Check captcha
      if (!captchaToken) {
        setCaptchaInvalid(true);
        return;
      }
      setCaptchaInvalid(false);

      // Iniate auth process
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
        options: { captchaToken },
      });
      if (error) {
        setErrorAlert("Incorrect email and/or password");
        return;
      }
      const { user } = data;

      // Check supabase to see if questions were complete
      const { data: userData, error: questionsError } = await supabase
        .from("user-profiles")
        .select("*")
        .eq("user_id", user?.id)
        .single();

      if (questionsError) {
        throw questionsError;
      }

      const questionsComplete = Boolean(userData?.questions_complete);
      const termsComplete = Boolean(userData?.terms_complete);
      const isBusiness = Boolean(userData?.is_business);

      if (!termsComplete) {
        navigate(AGREE_TO_TERMS_ROUTE);
        return;
      }

      if (!questionsComplete && !isBusiness) {
        navigate(USER_QUESTIONS_ROUTE);
        return;
      }

      navigate(USER_DASHBOARD_ROUTE);
    } catch (error) {
      setErrorAlert("An error occurred while attempting to log in. Please try again later.");
    } finally {
      // Increment captchaKey to force the captcha token to refresh
      setCaptchaKey((prev) => prev + 1);
      setLoading(false);
    }
  };

  return (
    <div className="tw-preflight">
      <Helmet>
        <title>EcoTrove | Login</title>
        <meta
          name="description"
          content="Log in to your EcoTrove account to save on your energy bills while using cleaner, predictable power. ."
        />
        <meta
          name="keywords"
          content="login to ecotrove account, affordable clean energy, accessible clean energy, Ali Sarilgan, Ozge Islegen-Wojdyla, clean energy subscription, lower energy bills, EcoTrove, green power, ecotrove team, Jess Kerlin, Tegan Fleishman, Christine Manegan"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <RDCCardWithBackground>
        {!showForgotPassword && (
          <>
            <h1 className="text-xl">Log In</h1>
            <form
              className="flex flex-col gap-4"
              onSubmit={login.handleSubmit((data) => handleLogin({ ...data, email: data.email.toLowerCase() }))}>
              <label className="flex flex-col gap-default w-full">
                <div className="flex items-center gap-default">
                  <EnvelopeIcon className="hero-icon hero-icon-sm" />
                  <p>Email</p>
                </div>
                <input
                  type="email"
                  className="daisy-input daisy-input-bordered aria-invalid:daisy-input-error aria-invalid:text-error"
                  placeholder="Your email address"
                  aria-invalid={Boolean(login.formState.errors.email)}
                  {...login.register("email", {
                    required: true,
                    pattern: { value: EMAIL_REGEX, message: "Please enter a valid email" },
                  })}
                />
                <RDCValidationError error={login.formState.errors.email?.message ?? ""} />
              </label>
              <label className="flex flex-col gap-default w-full">
                <div className="flex items-center gap-default">
                  <KeyIcon className="hero-icon hero-icon-sm" />
                  <p>Password</p>
                </div>
                <input
                  type="password"
                  className="daisy-input daisy-input-bordered aria-invalid:daisy-input-error aria-invalid:text-error"
                  placeholder="Your password"
                  aria-invalid={Boolean(login.formState.errors.password)}
                  // We shouldn't enforce a password regex on login in case there are legacy passwords that don't match our current requirements
                  // Strong password enforcement should be done on account creations instead
                  {...login.register("password", { required: true })}
                />
                <RDCValidationError error={login.formState.errors.password?.message ?? ""} />
              </label>
              <div
                role="button"
                className="daisy-link daisy-link-hover text-neutral-content ml-4 self-start"
                onClick={() => setShowForgotPassword(true)}>
                Forgot password?
              </div>
              <RDCCaptcha
                captchaKey={captchaKey}
                onSuccess={(token) => setCaptchaToken(token)}
                invalid={captchaInvalid}
              />
              <button className="daisy-btn daisy-btn-primary w-full" type="submit" disabled={loading}>
                Log In
              </button>
            </form>
            <div className="flex flex-col place-items-center gap-default">
              <p>Don't have an account?</p>
              <a className="daisy-link daisy-link-primary daisy-link-hover" href={CREATE_ACCOUNT_ROUTE}>
                Create account
              </a>
            </div>
          </>
        )}
        {/* Reset password form */}
        {showForgotPassword && (
          <>
            <h1 className="text-xl">Reset Password</h1>
            <form
              className="flex flex-col gap-4"
              onSubmit={resetPassword.handleSubmit((data) =>
                handleResetPassword({ ...data, email: data.email.toLowerCase() })
              )}>
              <label className="flex flex-col gap-default w-full">
                <div className="flex items-center gap-default">
                  <EnvelopeIcon className="hero-icon hero-icon-sm" />
                  <p>Email</p>
                </div>
                <input
                  type="email"
                  className="daisy-input daisy-input-bordered aria-invalid:daisy-input-error aria-invalid:text-error"
                  placeholder="Your email address"
                  aria-invalid={Boolean(resetPassword.formState.errors.email)}
                  {...resetPassword.register("email", { required: true, pattern: EMAIL_REGEX })}
                />
              </label>
              <RDCCaptcha
                captchaKey={captchaKey}
                onSuccess={(token) => setCaptchaToken(token)}
                invalid={captchaInvalid}
              />
              <button className="daisy-btn daisy-btn-primary w-full" type="submit" disabled={loading || resetSubmitted}>
                Send Reset Link
              </button>
            </form>
            <div
              role="button"
              className="flex w-full justify-center daisy-link daisy-link-hover text-neutral-content"
              onClick={() => setShowForgotPassword(false)}>
              Cancel
            </div>
          </>
        )}
      </RDCCardWithBackground>
    </div>
  );
};
