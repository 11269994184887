import { NavigateFunction } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { Link } from "../types";
import { ECOTROVE_TEST_EMAIL, GET_QUOTE_ROUTE, SUPABASE_UNAUTHENTICATED_ERROR_CODE } from "../constants";

export interface MonthData {
  monthNum: number;
  yearNum: number;
}

export const PLAN_NAMES = ["Free Insights", "Max Saver", "Green Saver"] as const;
export type Plan = (typeof PLAN_NAMES)[number];

export type MonthlyQuote = { saver?: number; super?: number } | { pge: number };

/**
 * Class that vends static utility functions.
 */
export class Utils {
  /**
   *Takes an `Link` array and maps them to a list of `<a>` elements with the specified styling.
   * @param links the `Link` array
   * @param options additional options to apply
   * @returns the mapped elements.
   */
  static getListElementsFromLinks = (
    links: Link[],
    options?: { className?: string; textStyle?: string; newTab?: boolean; keyOffset?: number }
  ): JSX.Element[] => {
    return links.map((props, i) => (
      <li className={options?.className} key={i + (options?.keyOffset ?? 0)}>
        <a
          className={options?.textStyle ?? "text-base-mobile lg:text-base"}
          href={props.url}
          aria-label={"ariaLabel" in props ? props.ariaLabel : undefined}
          target={options?.newTab ? "_blank" : undefined}
          rel={options?.newTab ? "noopener noreferrer" : undefined}>
          {"label" in props ? props.label : props.icon}
        </a>
      </li>
    ));
  };

  /**
   * Capitalizes the first character in each word in a string.
   * @param str the original string.
   * @returns the passed in string with the first letter capitalized.
   */
  static capitalize = (str: string): string => {
    return str
      .split(/\s+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  /**
   * Round to the nearest "n" (ex: `roundToNearestN(1921, 100)` = `1900`).
   * @param value the value to round
   * @param n the value to round against
   * @returns the rounded value.
   */
  static roundToNearestN = (value: number, n: number): number => {
    if (n <= 0) throw Error("n must be greater than 0");
    return Math.round(value / n) * n;
  };

  /**
   * Check whether the provided email is attached to a paying user.
   * @param email the email to check
   * @returns whether they are a paying user and if so, their name.
   */
  static isPayingUser = async (email: string): Promise<{ result: false } | { result: true; name: string }> => {
    const { data, error } = await supabase.from("user-profiles").select("name,stripe_customer_id").eq("email", email);
    if (error) throw new Error(error.message);
    // For now, we are calculating a paying user based on whether a Stripe ID is present.
    // In the future, we could optimize this check to use monthly_subscription, but that is currently not reflective of paying users only.
    return data.length && data[0].name && data[0].stripe_customer_id
      ? { result: true, name: data[0].name }
      : { result: false };
  };

  /**
   * Check whether the provided email is allowed to get a quote.
   * Note that the EcoTrove test email is always allowed to get a quote for testing purposes.
   * @param email the email to check
   * @returns whether or not the email is allowed to get a quote.
   */
  static canGetQuote = async (email: string): Promise<boolean> => {
    return email === ECOTROVE_TEST_EMAIL || !(await Utils.isPayingUser(email)).result;
  };

  /**
   * Formats a date in a pretty way (ie. January 1, 2025).
   * @param date the date to format
   * @returns the formatted year.
   */
  static getPrettyYear = (date: Date, options?: { excludeDay?: boolean }): string => {
    return date.toLocaleDateString("default", {
      month: "long",
      day: options?.excludeDay ? undefined : "numeric",
      year: "numeric",
      timeZone: "UTC",
    });
  };

  /**
   * Calculate the plan name based on certain DB fields.
   * NOTE: we should backfill the DB so this is a single field to make this calculation simpler.
   * @param props the props to calculate the plan name with
   * @returns the plan name.
   */
  static getPlanName = (props: {
    stripeCustomerId: string | undefined;
    monthlyQuote: MonthlyQuote | undefined;
    monthlySubscription: number | undefined;
  }): Plan => {
    const { stripeCustomerId, monthlyQuote, monthlySubscription } = props;
    // Case 1: no monthly subscription, user is Free Insight
    if (!stripeCustomerId) return "Free Insights";
    // Case 2: user that joined before 3-tiered pricing; they are on the Max Saver plan
    else if (monthlyQuote && "pge" in monthlyQuote) return "Max Saver";
    // Case 3: user that was offered multiple quotes; compare their current quote to the offered quotes to determine plan
    else if (monthlyQuote && monthlyQuote.saver && monthlyQuote.super) {
      return monthlyQuote.super === monthlySubscription ? "Green Saver" : "Max Saver";
    }
    // Cases 4 & 5: user only had one quote presented, they are that quote
    else if (monthlyQuote && monthlyQuote.saver) return "Max Saver";
    else if (monthlyQuote && monthlyQuote.super) return "Green Saver";
    // If none if these applied, they are Free Insights
    return "Free Insights";
  };

  /**
   * Get the price of a plan.
   * @param plan the plan to get the price for
   * @param monthlyQuote the monthly quote in the user's DB row
   * @returns the quote if one could be calculated, or undefined otherwise.
   */
  static getQuoteByPlan = (plan: Plan, monthlyQuote: MonthlyQuote): number | undefined => {
    switch (plan) {
      case "Free Insights":
        return undefined;
      case "Max Saver":
        if ("saver" in monthlyQuote) return monthlyQuote.saver;
        else if ("pge" in monthlyQuote) return monthlyQuote.pge;
        return undefined;
      case "Green Saver":
        if ("super" in monthlyQuote) return monthlyQuote.super;
        return undefined;
    }
  };

  /**
   * Handle a user's plan switch request.
   * NOTE: we will try to redirect them to the quote flow if possble, but if not, manual intervention is required.
   * @param props the props to handle the request with
   * @returns void
   */
  static handleChangePlan = async (props: {
    navigate: NavigateFunction;
    email: string;
    currentPlan: Plan;
    currentSubscription: number | undefined;
    newPlan: Plan;
    monthlyQuote: MonthlyQuote | undefined;
    quoteUrl: string | undefined;
  }): Promise<void> => {
    const { navigate, email, currentPlan, currentSubscription, newPlan, monthlyQuote, quoteUrl } = props;
    if (currentPlan === newPlan) return;
    switch (currentPlan) {
      // Free insight customers can self-service through the quote flow
      case "Free Insights":
        // If we didn't save a quote URL for the user, send them to the beginning of the flow
        if (!quoteUrl) {
          navigate(GET_QUOTE_ROUTE);
        } else {
          // Extract the pathname and search string so the origin is preserved in dev vs. prod
          const url = new URL(quoteUrl);
          navigate(`${url.pathname}${url.search}`);
        }
        break;
      // We need manual intervention for paid customers at this time
      case "Max Saver":
      case "Green Saver":
        // If we've generated a quote for the plan the user is requesting, we'll add it to the table
        // Otherwise, manual intervention to generate a quote may be needed
        const newPrice = monthlyQuote ? this.getQuoteByPlan(newPlan, monthlyQuote) : undefined;
        const { error } = await supabase.from("subscription-change-requests").insert({
          email,
          current_plan: currentPlan,
          current_plan_price: currentSubscription,
          change_plan_to: newPlan,
          new_plan_price: newPrice,
        });
        if (error?.code === SUPABASE_UNAUTHENTICATED_ERROR_CODE) {
          throw new Error("unauthenticated");
        } else if (error) throw new Error(error.message);
        break;
      default:
        // This case should not happen (defensive programming)
        throw new Error(`unrecognized plan: ${currentPlan}`);
    }
  };

  /**
   * Open a dialog modal.
   * @param modalId the ID of the dialog element to open
   */
  static openDialogModal = (modalId: string) => {
    // Safe cast: as long as modalId is a dialog element, this will succeed
    (document.getElementById(modalId) as HTMLDialogElement).showModal();
  };

  /**
   * close a dialog modal.
   * @param modalId the ID of the dialog element to close
   */
  static closeDialogModal = (modalId: string) => {
    // Safe cast: as long as modalId is a dialog element, this will succeed
    (document.getElementById(modalId) as HTMLDialogElement).close();
  };

  /**
   * Generates an array of numbers from start to end, increasing.
   * @param start the number to start at (inclusive)
   * @param end the number to end at (inclusive)
   * @returns the array of numbers;
   */
  static range = (start: number, end: number): number[] => {
    if (end <= start) throw new Error("start must be less than end");
    return [...Array(1 + end - start).keys()].map((value) => start + value);
  };
}
