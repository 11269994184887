import { useEffect, useState } from "react";
import {
  RDCCheckAvailabilityHero,
  RDCNavbar,
  RDCImageCard,
  RDCFinishedScrollingForm,
  RDCFooter,
  RDCAutoScrollCarousel,
  RDCCustomerReviewCard,
  RDCCarbonEquivalentSlider,
} from "../components";
import EasySetupGraphic from "../images/graphics/EasySetupGraphic.png";
import NewPowerBillGraphic from "../images/graphics/NewPowerBillGraphic.png";
import TransparentPricingGraphic from "../images/graphics/TransparentPricingGraphic.png";
import IndustryProvidersGraphic from "../images/graphics/IndustryProvidersGraphic.png";
import PrivacyFirstGraphic from "../images/graphics/PrivacyFirstGraphic.png";
import OnOffGraphic from "../images/graphics/OnOffGraphic.png";
import CustomerReviewGraphic from "../images/graphics/CustomerReviewGraphic.png";
import { CustomerReview, CustomerReviewService } from "../services/CustomerReviewService";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/solid";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet";
import { MOBILE_SNAP_SPACER } from "../../constants";
import { useSearchParams } from "react-router-dom";
import { useAlertStore, useReferrerStore } from "../stores";
import { supabase } from "../../supabaseClient";
import { Utils } from "../../utils";

const PAGE_ID = "landing-page";
const PAGE_NAME = "landingPage";

const CEO_VIDEO_URL = "https://www.youtube.com/embed/c-rV5eZ1VLs";

const CUSTOMER_TESTIMONIAL_VIDEO_URLS = [
  "https://www.youtube.com/embed/aJ0VNUbOGik",
  "https://www.youtube.com/embed/_R2PnLi54Eo",
  "https://www.youtube.com/embed/YLiP0P_0MaI",
];

/**
 * Landing page that should be displayed to the user upon arrival.
 * @returns a React `FunctionComponent`.
 */
export const RDCLandingPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [reviews] = useState<CustomerReview[]>(CustomerReviewService.getReviews({}));
  const { setErrorAlert } = useAlertStore();
  const { setReferrer } = useReferrerStore();

  useEffect(() => {
    mixpanel.track(PAGE_NAME);
  }, []);

  // Check for a valid referrer param, and if one exists, add it to the global store
  useEffect(() => {
    const isValidReferrer = async () => {
      try {
        const referrer = searchParams.get("referrer");
        if (referrer) {
          // If the user is currently logged in, display a warning message
          const {
            data: { user },
          } = await supabase.auth.getUser();
          if (user) {
            setErrorAlert("Referral is only valid for new users. Please sign out to continue.");
          }
          // If a valid (paying) user is found, add the referrer to the store
          const isPayingUser = await Utils.isPayingUser(referrer);
          if (!user && isPayingUser.result) setReferrer({ email: referrer, name: isPayingUser.name });
          // Remove the parameter since it's no longer needed
          searchParams.delete("referrer");
          setSearchParams(searchParams, { replace: true });
        }
      } catch (error) {
        setErrorAlert("An error occurred while checking referral code. Please try again later.");
      }
    };
    isValidReferrer();
  }, [searchParams, setSearchParams, setReferrer, setErrorAlert]);

  return (
    <>
      <Helmet>
        <title>EcoTrove | Lower and Flat Power Bills</title>
        <meta
          name="description"
          content="EcoTrove is your energy concierge for cheaper, greener and more predictable power bills."
        />
        <meta
          name="keywords"
          content="fixed-price energy, energy concierge, clean energy subscription, lower energy bills, EcoTrove, green power, clean energy, flat rate energy bills, lower utility bills, flatten utility bills, renewable energy, pg&E, sce, sdg&E"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="EcoTrove - Lower and Flat Power Bills" />
        <meta
          property="og:description"
          content="EcoTrove is your energy concierge for cheaper, greener, and more predictable power bills."
        />
        <meta property="og:image" content="https://i.postimg.cc/5NsyKGLv/Screenshot-2024-12-02-at-8-34-16-AM.png" />
        <meta property="og:url" content="https://ecotrove.com/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="EcoTrove - Lower and Flat Power Bills" />
        <meta
          name="twitter:description"
          content="EcoTrove is your energy concierge for cheaper, greener, and more predictable power bills."
        />
        <meta name="twitter:image" content="https://i.postimg.cc/5NsyKGLv/Screenshot-2024-12-02-at-8-34-16-AM.png" />
      </Helmet>
      <div id={PAGE_ID} className="tw-preflight snap-scroll-page">
        {/* Navbar */}
        <RDCNavbar contentId={PAGE_ID} />

        {/* View height: hero */}
        <div className="snap-start flex flex-col min-h-[calc(100%-72px)]">
          <span className="grow pt-12 lg:pt-20 px-content-mobile lg:px-content mb-default">
            <RDCCheckAvailabilityHero />
          </span>
        </div>

        {/* Customer reviews */}
        <div id="customer-reviews" className="snap-start flex flex-col min-h-full bg-gradient-to-b from-secondary py-4">
          <div className="flex justify-center items-center gap-default pb-4">
            <p className="text-sm lg:text-base">See why our customers rate us "excellent" on Trustpilot</p>
            <button
              aria-label="scroll down"
              className="hidden lg:flex daisy-btn daisy-btn-ghost daisy-btn-circle"
              onClick={() => document.getElementById("customer-reviews")?.scrollIntoView({ behavior: "smooth" })}>
              <ChevronDoubleDownIcon className="hero-icon hero-icon-sm" />
            </button>
          </div>
          <RDCAutoScrollCarousel
            carouselId="customer-review-carousel"
            items={reviews.map((review) => (
              <RDCCustomerReviewCard review={review} />
            ))}
            autoScrollMS={3000}
          />
        </div>

        {/* Save more stress less */}
        <div className="snap-start flex flex-col min-h-full p-content-mobile lg:p-content gap-content-mobile lg:gap-content">
          <h2 className="text-center">Save more, stress less.</h2>
          <div className="grid grid-col-1 lg:grid-cols-3 gap-20 lg:gap-8 justify-items-center">
            {[
              {
                imgUrl: EasySetupGraphic,
                imgAlt: "three easy steps to set up an account",
                headerText: "Easy 2 minute set-up",
                bodyText:
                  "Connect your utility and EcoTrove accounts to allow us to make your power bills work for you.",
              },
              {
                imgUrl: NewPowerBillGraphic,
                imgAlt: "new rate will be cheaper than the old rate",
                headerText: "Lock in the cheapest rates",
                bodyText:
                  "Replace your utility-issued power bills with a flat-price payment plan that is up to $500*/yr cheaper.",
              },
              {
                imgUrl: TransparentPricingGraphic,
                imgAlt: "EcoTrove has a transparent pricing model",
                headerText: "Transparent pricing",
                bodyText: "EcoTrove guarantees you savings and only makes money for savings secured above and beyond.",
              },
            ].map((props, i) => (
              <div key={i} className="max-md:snap-start text-center">
                <RDCImageCard {...props} />
              </div>
            ))}
          </div>
          {MOBILE_SNAP_SPACER}
        </div>

        {/* Carbon equivalent slider */}
        <div className="snap-start flex flex-col items-center gap-content-mobile lg:gap-content bg-neutral mb-12 p-content-mobile lg:p-content min-h-[calc(100%-96px)]">
          <h2 className="leading-none">Check your savings.</h2>
          <RDCCarbonEquivalentSlider pageName={PAGE_NAME} />
        </div>

        {/* CEO message */}
        <div className="snap-start flex flex-col items-center min-h-full p-content-mobile lg:p-content gap-content-mobile lg:gap-content text-center">
          <div className="flex flex-col items-center gap-4 sm:gap-4 space-y-5 lg:space-y-0">
            <h2 className="leading-none">
              Message
              <br />
              from our CEO
            </h2>
            <p className="text-neutral-content lg:w-1/2">
              We are building EcoTrove to be your Energy Champion. We believe you deserve to use the power you need,
              without breaking the bank or the environment.
            </p>
            <iframe className="rounded-3xl aspect-video w-full" src={CEO_VIDEO_URL} title="Video from CEO" />
          </div>
        </div>


        {/* Why EcoTrove */}
        <div className="snap-start flex flex-col items-center bg-neutral-50 p-content-mobile lg:p-content gap-content-mobile lg:gap-content">
          <div className="text-center">
            <h2>Why EcoTrove?</h2>
            <p className="text-neutral-content mt-4">We are loved by our customers and trusted by the industry.</p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-24">
            {[
              {
                imgUrl: CustomerReviewGraphic,
                imgAlt: "customer review example",
                headerText: "Loved by customers",
                bodyText:
                  "Every single EcoTrove customer is paying less for their power bills compared to what they would have paid their utility. Read their reviews to learn more.",
              },
              {
                imgUrl: PrivacyFirstGraphic,
                imgAlt: "data privacy provider logos",
                headerText: "Privacy first",
                bodyText:
                  "EcoTrove has adopted robust data security and privacy measures to comply with US Department of Energy's DataGuard Energy Data Privacy Program.",
              },
              {
                imgUrl: IndustryProvidersGraphic,
                imgAlt: "utilitiy provider logos",
                headerText: "Trusted by the industry",
                bodyText:
                  "EcoTrove is an approved third-party service provider for leading utilities including Pacific Gas & Electric, Southern California Edison, and San Diego Gas & Electric.",
              },
              {
                imgUrl: OnOffGraphic,
                imgAlt: "on/off switch",
                headerText: "Cancel anytime",
                bodyText:
                  "Never pay a utility bill again and let EcoTrove optimize your utility bills. Cancel anytime if you're not 100% satisfied.",
              },
            ].map((props, i) => (
              <div key={i} className="max-md:snap-start text-center lg:text-start">
                <RDCImageCard key={props.headerText} {...props} />
              </div>
            ))}
            {MOBILE_SNAP_SPACER}
          </div>
        </div>

        {/* Customer testimonials */}
        <div className="snap-start p-content-mobile lg:p-content">
          <div className="flex flex-col gap-4 text-center">
            <h2>
              Enjoy affordable,
              <br />
              fixed prices every month.
            </h2>
            <p className="text-neutral-content">Just like they do.</p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-8 justify-items-center">
            {CUSTOMER_TESTIMONIAL_VIDEO_URLS.map((url, i) => (
              <iframe
                key={i}
                className="snap-start grow rounded-3xl w-full aspect-[9/16] my-content-mobile lg:my-content"
                src={url}
                title="Customer testimonial"
              />
            ))}
            {MOBILE_SNAP_SPACER}
          </div>
        </div>

        {/* View height: finished scrolling & footer */}
        <div className="snap-start flex flex-col min-h-full">
          <RDCFinishedScrollingForm />
          <div className="grow" />
          <RDCFooter showExplainer />
        </div>
      </div>
    </>
  );
};
