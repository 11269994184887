import { FieldValues, Path, RegisterOptions, UseFormRegister } from "react-hook-form";
import { InputProps } from ".";
import { HTMLInputTypeAttribute } from "react";

/**
 * Props to pass to a `RDCTextWithButtonInput` component.
 */
export interface RDCTextWithButtonInputProps<T extends FieldValues> extends InputProps {
  name: Path<T>;
  placeholder: string;
  invalid: boolean;
  register: UseFormRegister<T>;
  validations: RegisterOptions<T, Path<T>>;
  buttonText: string;
  buttonType: "primary" | "secondary" | "accent";
  disabled?: boolean;
  maxLength?: number;
  type?: HTMLInputTypeAttribute;
}

/**
 * Input component that contains both a text input and a submit button.
 * @param props
 * @returns
 */
export function RDCTextWithButtonInput<T extends FieldValues>(props: RDCTextWithButtonInputProps<T>): JSX.Element {
  const { name, placeholder, invalid, register, validations, buttonText, buttonType, disabled, maxLength, type } =
    props;

  return (
    <label
      className="w-full daisy-input daisy-input-lg daisy-input-bordered flex justify-between items-center aria-invalid:daisy-input-error aria-invalid:text-error"
      aria-invalid={invalid}>
      <input
        className="grow min-w-0"
        data-test={`${props["data-test"]}-input`}
        style={{ fontFamily: "inherit" }}
        id={name}
        type={type ?? "text"}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        {...register(name, validations)}
      />
      <button
        className={`daisy-btn ${`daisy-btn-${buttonType}`} -mr-4`}
        data-test={`${props["data-test"]}-button`}
        type="submit"
        disabled={disabled}>
        {buttonText}
      </button>
    </label>
  );
}
