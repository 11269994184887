import HeroBillGraphic1 from "../images/graphics/HeroBillGraphic1.png";
import HeroBillGraphic2 from "../images/graphics/HeroBillGraphic2.png";
import PGELogo from "../images/logos/PGELogo.svg";
import SCELogo from "../images/logos/SCELogo.svg";
import SDGELogo from "../images/logos/SDGELogo.svg";
import TrustPilotLogo from "../images/logos/TrustPilotLogo.svg";
import SmartEnergyLogo from "../images/logos/SmartEnergyLogo.svg";
import { RDCCheckAvailabilityForm } from "./RDCCheckAvailabilityForm";
import { GET_QUOTE_ROUTE, PGE_URL, SCE_URL, SDGE_URL, TRUSTPILOT_URL, SMARTENERGY_URL } from "../../constants";
import { useNavigate } from "react-router-dom";
import { motion, useInView } from "motion/react";
import { useEffect, useRef, useState } from "react";
import { useReferrerStore } from "../stores";
import mixpanel from "mixpanel-browser";

const ANIMATION_DURATION_SECONDS = 2;

/**
 * Component that allows a user to to check EcoTrove availability based on ZIP code.
 * @returns a React `FunctionComponent`.
 */
export const RDCCheckAvailabilityHero: React.FC = () => {
  const { referrer } = useReferrerStore();
  const navigate = useNavigate();
  const animationRef = useRef(null);
  const isInView = useInView(animationRef);
  // Use a state variable that will be set once to indicate when an animation should run
  const [runAnimation, setRunAnimation] = useState<boolean>(false);

  useEffect(() => {
    if (isInView) {
      setRunAnimation(true);
    }
  }, [isInView]);

  /**
   * Returns an animated set of images that behaves differently based on the screen size using Framer Motion.
   * @param props the props to render the images with
   * @returns a div containig the images.
   */
  const getAnimatedBillGraphic = (props: { isSmallScreen: boolean }): JSX.Element => {
    const { isSmallScreen } = props;
    const startX = window.innerWidth / 2;
    const secondImageOffset = 10;
    return (
      <div
        ref={animationRef}
        className={`${
          isSmallScreen ? "flex lg:hidden py-8" : "hidden lg:flex"
        } justify-items-center overflow-x-hidden pb-10`}>
        <motion.img
          className="w-1/2"
          src={HeroBillGraphic1}
          alt="bill before EcoTrove"
          initial={{ x: isSmallScreen ? -startX : startX }}
          animate={runAnimation || !isSmallScreen ? { x: isSmallScreen ? secondImageOffset / 2 : 0 } : undefined}
          transition={{
            type: "smooth",
            duration: ANIMATION_DURATION_SECONDS,
          }}
        />
        <motion.img
          className="w-1/2 z-10"
          src={HeroBillGraphic2}
          alt="bill after EcoTrove"
          initial={{ x: startX, y: secondImageOffset }}
          animate={
            runAnimation || !isSmallScreen
              ? { x: isSmallScreen ? -secondImageOffset / 2 : -secondImageOffset, y: secondImageOffset }
              : undefined
          }
          transition={{
            type: "smooth",
            duration: ANIMATION_DURATION_SECONDS,
            delay: ANIMATION_DURATION_SECONDS,
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2">
        {/* Left column */}
        <div className="col-span-1 flex flex-col">
          {/* Text blurb */}
          <div className="flex-none">
            <div className="grid grid-cols-1 gap-3">
              <p className="daisy-badge daisy-badge-neutral daisy-badge-md lg:daisy-badge-lg">
                Save up to 10% with flat power bills
              </p>
              <h1 className={`text-45 -ml-1 ${referrer && "w-5/6"}`}>
                {referrer ? (
                  `${referrer.name} is giving you an exclusive EcoTrove discount`
                ) : (
                  <>
                    Flat Power Bills,
                    <br className="block lg:hidden" /> Big Savings.
                  </>
                )}
              </h1>
              <span className="text-neutral-content">
                <br />
                <p className="text-lg">
                  EcoTrove is your Energy Ally. We optimize your PG&E account and lower your bills.
                </p>
                <br />
                <p className="text-lg">
                  No surprises, no interruptions. Turn your power bills into a flat price monthly subscription and never overpay a power bill again.
                </p>
              </span>
            </div>
          </div>
          {/* ZIP code input */}
          <div className="grow mt-8 lg:mt-14 lg:w-3/4">
            <RDCCheckAvailabilityForm
              data-tests={{ zipCode: "zipcode-hero" }}
              onSubmit={(data) => navigate(`${GET_QUOTE_ROUTE}?zipCode=${encodeURIComponent(data.zipCode)}`)}
            />
          </div>
        </div>
        {/* Right Column */}
        <div className="mt-8 ml-6">{getAnimatedBillGraphic({ isSmallScreen: false })}</div>
        {/* Trusted by icons */}
        <div className="col-span-1 lg:col-span-2">
          <div className="grid justify-items-center lg:justify-items-start text-neutral-500">
            <p className="max-md:snap-start text-sm lg:ml-5">Reviews and service area:</p>
            <div className="grid grid-cols-5 justify-evenly lg:justify-start justify-items-center mt-3 lg:gap-8">
              {[
                { href: TRUSTPILOT_URL, img: TrustPilotLogo, alt: "TrustPilot logo" },
                { href: SMARTENERGY_URL, img: SmartEnergyLogo, alt: "SmartEnergy logo" },
                { href: PGE_URL, img: PGELogo, alt: "Pacific Gas and Electric logo" },
                { href: SCE_URL, img: SCELogo, alt: "Southern California Edison logo" },
                { href: SDGE_URL, img: SDGELogo, alt: "San Diego Gas and Electric logo" },
              ].map(({ href, img, alt }, i) => (
                <a
                  key={i}
                  className="daisy-btn daisy-btn-ghost"
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => mixpanel.track(`Visit provider URL: ${href}`)}>
                  <img src={img} alt={alt} />
                </a>
              ))}
            </div>
          </div>
        </div>
        {getAnimatedBillGraphic({ isSmallScreen: true })}
      </div>
    </div>
  );
};
