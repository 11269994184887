import { useParams } from "react-router-dom";
import { RDCCardWithBackground } from "../components";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";

/**
 * Page that notifies a user to check their email for confirmation.
 */
export const RDCConfirmEmailPage: React.FC = () => {
  const { email } = useParams();

  useEffect(() => {
    mixpanel.track("confirmEmail");
  }, []);

  return (
    <div className="tw-preflight">
      <RDCCardWithBackground>
        <h1 className="text-xl text-center overflow-scroll">
          We have sent a confirmation email to <b>{email}</b>.<br />
          <br />
          Please confirm to log in.
        </h1>
      </RDCCardWithBackground>
    </div>
  );
};
