import { RDCEcoTroveLogoLink } from "./RDCEcoTroveLogoLink";
import {
  ACTION_LINKS,
  ECOTROVE_EMAIL_INFO,
  HOW_IT_WORKS_ROUTE,
  MAIN_MENU_LINKS_AUTH,
  MAIN_MENU_LINKS_UNAUTH,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
} from "../../constants";
import { Utils } from "../../utils";
import { Link } from "../../types";
import FacebookLogo from "../images/logos/FacebookLogo.svg";
import InstagramLogo from "../images/logos/InstagramLogo.svg";
import TwitterLogo from "../images/logos/TwitterLogo.svg";
import LinkedInLogo from "../images/logos/LinkedInLogo.svg";
import TikTokLogo from "../images/logos/TikTokLogo.svg";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { supabase } from "../../supabaseClient";

const FOOTER_LINKS: Link[] = [
  { label: "Privacy Policy", url: PRIVACY_POLICY_ROUTE },
  { label: "Terms of Service", url: TERMS_OF_SERVICE_ROUTE },
  // TODO: cookies settings
];

const SOCIAL_MEDIA_LINKS: Link[] = [
  {
    icon: <EnvelopeIcon className="hero-icon hero-icon-sm" />,
    ariaLabel: "email",
    url: `mailto:${ECOTROVE_EMAIL_INFO}`,
  },
  { icon: <img src={FacebookLogo} alt="Facebook" />, url: "https://www.facebook.com/people/EcoTrove/61553413146538/" },
  { icon: <img src={InstagramLogo} alt="Instagram" />, url: "https://www.instagram.com/ecotrove_energy/" },
  { icon: <img src={TwitterLogo} alt="Twitter/X" />, url: "https://x.com/ecotrove_energy" },
  { icon: <img src={LinkedInLogo} alt="LinkedIn" />, url: "https://www.linkedin.com/company/ecotrove" },
  { icon: <img src={TikTokLogo} alt="TikTok" />, url: "https://www.tiktok.com/@ecotrove_energy" },
];

/**
 * Props to pass to a `RDCFooter`.
 */
export interface RDCFooterProps {
  showExplainer?: boolean;
}

/**
 * Footer component that should be rendered on every page.
 * @returns a React component.
 */
export const RDCFooter: React.FC<RDCFooterProps> = (props) => {
  const { showExplainer } = props;
  const [isSignedIn, setIsSignedIn] = useState(false);

  // Check whether the user is logged in so that the correct auth button can be rendered
  useEffect(() => {
    const checkLoggedIn = async () => {
      // Comment this out for local testing
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) setIsSignedIn(true);

      // Uncomment this for local testing
      // const user = { id: process.env.REACT_APP_TEST_USER_ID ?? "" };
      // setIsSignedIn(Boolean(user));
    };
    checkLoggedIn();
  }, []);
  return (
    <footer className="flex flex-col items-center p-content-mobile lg:p-content w-full gap-8">
      {showExplainer && (
        <>
          <p className="w-full">
            * 74% of EcoTrove customers save up to $500/yr, and 26% save more than $500. Live estimates are available at{" "}
            <a href={HOW_IT_WORKS_ROUTE} className="daisy-link">
              ecotrove.com/how-it-works
            </a>
            .
          </p>
          <hr className="w-full" />
        </>
      )}
      <div className="flex flex-col lg:flex-row justify-between items-center w-full gap-4">
        {/* Logo */}
        <span className="-ml-2">
          <RDCEcoTroveLogoLink />
        </span>
        {/* Center menu */}
        <div className="flex justify-center">
          <ul className="daisy-menu daisy-menu-md lg:daisy-menu-horizontal">
            {Utils.getListElementsFromLinks([
              ...(isSignedIn ? MAIN_MENU_LINKS_AUTH : MAIN_MENU_LINKS_UNAUTH),
              isSignedIn ? ACTION_LINKS.authenticated : ACTION_LINKS.unauthenticated,
            ])}
          </ul>
        </div>
        {/* Social media */}
        <div className="flex-none flex justify-center -mr-2">
          <ul className="flex items-center">
            {Utils.getListElementsFromLinks(SOCIAL_MEDIA_LINKS, {
              className: "daisy-btn daisy-btn-ghost daisy-btn-circle",
              newTab: true,
            })}
          </ul>
        </div>
      </div>
      <hr className="w-full" />
      <div className="flex flex-col lg:flex-row text-3xl lg:text-sm w-full">
        {/* Left corner (top item on mobile screens) */}
        <div className="flex flex-col items-center lg:items-start">
          <a className="flex items-center gap-x-default" href={`mailto:${ECOTROVE_EMAIL_INFO}`}>
            <EnvelopeIcon className="hero-icon hero-icon-xs" />
            <p className="daisy-link daisy-link-hover text-sm">{ECOTROVE_EMAIL_INFO}</p>
          </a>
          <div className="flex items-center gap-x-default">
            <p className="text-sm">&copy;</p>
            <p className="text-sm">{new Date().getUTCFullYear()} EcoTrove. All rights reserved.</p>
          </div>
        </div>
        <div className="grow" />
        {/* Right corner (bottom item on mobile screens) */}
        <ul className="flex flex-col lg:flex-row mt-4 lg:mt-0 items-center gap-x-4 -mr-2 lg:-mb-5">
          {Utils.getListElementsFromLinks(FOOTER_LINKS, { className: "daisy-btn daisy-btn-sm daisy-btn-ghost" })}
        </ul>
      </div>
    </footer>
  );
};
