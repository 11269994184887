/** Props to pass to a `RDCTrustPilotStars` component. */
interface RDCTrustPilotStarsProps {
  rating: number;
}

type StarFill = "full" | "half" | "empty";

/**
 * Component that shows TrustPilot-style stars based on a passed in rating.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCTrustPilotStars: React.FC<RDCTrustPilotStarsProps> = (props) => {
  const { rating } = props;

  // Don't return anything for an invalid rating
  if (rating < 0 || rating > 5) {
    return <div />;
  }

  const fullStars = Math.floor(rating);
  const hasHalf = rating % 1 > 0;
  const emptyStars = 5 - fullStars;

  // Create an array with string values indicating whether the star should be full, half, or empty
  const stars: StarFill[] = [];
  for (let i = 0; i < fullStars; i++) stars.push("full");
  if (hasHalf) stars.push("half");
  for (let i = 0; i < emptyStars; i++) stars.push("empty");

  return (
    <ul className="flex gap-x-0.5">
      {stars.map((fill, i) => {
        switch (fill) {
          case "full":
          case "empty":
            return (
              <div key={i} className={`${fill === "full" ? "bg-emerald-500" : "bg-gray-300"} p-1`}>
                <div className="daisy-mask daisy-mask-star bg-white size-3" aria-label="star" />
              </div>
            );
          case "half":
            return (
              <div key={i} className="flex items-center">
                <div className="bg-emerald-500 py-1 pl-1">
                  <div
                    className="daisy-mask daisy-mask-star bg-white daisy-mask-half-1 w-1.5 h-3"
                    aria-label="first half star"
                  />
                </div>
                <div className="bg-gray-300 py-1 pr-1">
                  <div
                    className="bg-white daisy-mask daisy-mask-star daisy-mask-half-2 w-1.5 h-3"
                    aria-label="second half star, not filled in"
                  />
                </div>
              </div>
            );
          default:
            return <div />;
        }
      })}
    </ul>
  );
};
