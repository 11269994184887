import { RDCCardWithBackground, RDCUpdatePasswordForm } from "../components";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../constants";
import mixpanel from "mixpanel-browser";
import { useAlertStore } from "../stores";

/**
 * Form page that allows a user to update their password.
 * @returns a React component.
 */
export const RDCUpdatePasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const { setErrorAlert } = useAlertStore();

  useEffect(() => {
    mixpanel.track("updatePassword");
  }, []);

  return (
    <div className="tw-preflight">
      {/* Update password form */}
      <RDCCardWithBackground>
        <h1 className="text-xl">Update Password</h1>
        <RDCUpdatePasswordForm
          onSuccess={() => navigate(LOGIN_ROUTE)}
          onError={() => setErrorAlert("An error occurred while attempting to update password. Please try again later")}
        />
      </RDCCardWithBackground>
    </div>
  );
};
