import { Turnstile } from "@marsidev/react-turnstile";
import { RDCValidationError } from "./RDCValidationError";

/**
 * Props to pass to a `RDCCaptcha`.
 */
export interface RDCCaptchaProps {
  /**
   * Key that can be incremented to force the captcha to reload.
   */
  captchaKey: number;
  onSuccess: (token: string) => void;
  invalid: boolean;
}

/**
 * Captcha component that uses a Cloudflare Turnstile.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCCaptcha: React.FC<RDCCaptchaProps> = (props) => {
  const { captchaKey, onSuccess, invalid } = props;

  return (
    <div key={captchaKey} className="my-default">
      <Turnstile
        siteKey="0x4AAAAAAAiqGq8fYinFCIYM"
        onSuccess={onSuccess}
        // Silently intercept the error so it doesn't block the entire screen (the turnstile will still show its own error message in this case)
        // Consumers of this component should wait for `onSuccess` to proceed
        onError={() => {}}
      />
      <RDCValidationError error={invalid ? "Please complete captcha" : ""} />
    </div>
  );
};
