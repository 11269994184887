import { useEffect } from "react";
import FaceX from "../images/faces/FaceX.svg";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet";

const FACE_X = <img className="size-24" src={FaceX} alt="circular face with x eyes" />;

const CONFIG: Record<StatusCode, { readable: string; icon: JSX.Element; message: string }> = {
  403: { readable: "Access Denied", icon: FACE_X, message: "Sorry, you don't have access to this page." },
  404: { readable: "Not Found", icon: FACE_X, message: "Sorry, the page you're looking for could not be found." },
};

/**
 * Status codes that can be displayed as a page.
 */
export type StatusCode = 403 | 404;

/**
 * Props to pass to a `RDCStatusCodePage`.
 */
export interface RDCStatusCodeProps {
  code: StatusCode;
}

/**
 * Page that displays the specified status code.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCStatusCodePage: React.FC<RDCStatusCodeProps> = (props) => {
  const { code } = props;
  const { readable, icon, message } = CONFIG[code];

  // Track page visit on Mixpanel
  useEffect(() => {
    // If the status code is 404, add additional information about which URL the user attempted to navigate to
    mixpanel.track(
      `Status code page: ${code}${code === 404 ? ` while attempting to navigate to ${window.location.href}` : ""}`
    );
  }, [code]);

  return (
    <>
      <Helmet>
        {/* Prevent status code pages from being indexed */}
        <meta name="robots" content="noindex" />
      </Helmet>
      <main className="tw-preflight flex flex-col place-content-center place-items-center text-center min-h-dvh p-content-mobile lg:p-content gap-6">
        {icon}
        <h1 className="text-primary">
          {code}: {readable}
        </h1>
        <h2 className="text-2xl">{message}</h2>
        <a className="daisy-btn daisy-btn-primary" href="/">
          Take Me Home
        </a>
      </main>
    </>
  );
};
