import React, { useState } from "react";
import { RDCConfirmPasswordsInput } from "./RDCConfirmPasswordInputs";
import { RDCCaptcha } from "./RDCCaptcha";
import { SubmitHandler, useForm } from "react-hook-form";
import { supabase } from "../../supabaseClient";

interface UpdatePasswordForm {
  password: string;
  confirmPassword: string;
}

/**
 * Props to pass to a `RDCUpdatePasswordFormProps`.
 */
export interface RDCUpdatePasswordFormProps {
  onSuccess: () => void;
  onError: () => void;
  submitButtonClass?: string;
}

/**
 * Form that allows a user to update their password.
 * @returns a React component.
 */
export const RDCUpdatePasswordForm: React.FC<RDCUpdatePasswordFormProps> = (props) => {
  const { onSuccess, onError, submitButtonClass } = props;
  const [captchaToken, setCaptchaToken] = useState<string>("");
  const [captchaInvalid, setCaptchaInvalid] = useState<boolean>(false);
  const [captchaKey, setCaptchaKey] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const updatePassword = useForm<UpdatePasswordForm>();

  /**
   * Handler for password update events
   */
  const handleUpdatePassword: SubmitHandler<UpdatePasswordForm> = async ({ password }) => {
    setLoading(true);
    try {
      // Check captcha
      if (!captchaToken) {
        setCaptchaInvalid(true);
        return;
      }
      setCaptchaInvalid(false);
      // Attempt to update password
      const { data, error } = await supabase.auth.updateUser({ password });
      if (data) {
        onSuccess();
      } else if (error) {
        throw error;
      }
    } catch (error) {
      onError();
    } finally {
      // Increment captchaKey to force the captcha token to refresh
      setCaptchaKey((prev) => prev + 1);
      setLoading(false);
    }
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={updatePassword.handleSubmit(handleUpdatePassword)}>
      {/* New password + confirmation */}
      <RDCConfirmPasswordsInput form={updatePassword} labelPrefix="new" />
      {/* Captcha */}
      <RDCCaptcha captchaKey={captchaKey} onSuccess={(token) => setCaptchaToken(token)} invalid={captchaInvalid} />
      {/* Submit */}
      <button className={`daisy-btn daisy-btn-primary ${submitButtonClass}`} type="submit" disabled={loading}>
        Update Password
      </button>
    </form>
  );
};
