import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { RDCCollapseList, RDCContactUsModal, RDCFooter, RDCNavbar } from "../components";
import WindmillArtVertical from "../images/art/WindmillArtVertical.png";
import { Utils } from "../../utils";
import { useAlertStore } from "../stores";
import { supabase } from "../../supabaseClient";

const CONTACT_US_MODAL_ID = "contact-us-modal";

const SUPPORT_FAQS: { q: string; a: string }[] = [
  {
    q: "How does EcoTrove’s payment plan work?",
    a: "You pay EcoTrove a predictable, fixed price every month and we pay your electricity and natural gas bills with your utility on your behalf. You can still monitor your power bills and make changes to your utility account, but you stop paying your utility.",
  },
  {
    q: "This sounds too good to be true - how on earth do you make money?",
    a: "EcoTrove's purpose is to make clean energy affordable and accessible. Our first priority is to ensure you use all the power you need with guaranteed fixed prices that are lower. By automatically analyzing all available energy data and incentives, EcoTrove's algorithms can take any household or small business power bill and drastically reduce it. We pass on the majority of the savings we find to you, and any additional saving we are able to secure above and beyond becomes our profit.",
  },
  {
    q: "How can EcoTrove guarantee a fixed price?",
    a: "Our proprietary technology forecasts how much power you will need in the following year, identifies the cheapest possible green energy options, most appropriate rate plans and government incentives applicable to you. We accept the risk of price fluctuations on your behalf so that you can use the power you need, without worrying about large bill swings.",
  },
  {
    q: "Does EcoTrove cover both electricity and natural gas?",
    a: "Yes, your EcoTrove payment plan covers both electricity and natural gas. EcoTrove automatically switches your electricity source to a renewable energy generator. Since there is no renewable energy option to power your gas-appliances (gas stove, gas heater etc.), you will continue receiving natural gas without interruption.",
  },
  {
    q: "How does EcoTrove switch me to clean energy?",
    a: "EcoTrove identifies the cheapest renewable energy generator available in your area. This typically corresponds to a utility-approved Community Choice Aggregator or a community solar provider. We contact the renewable energy generator and your utility on your behalf, and request them to immediately switch your electricity source to a cleaner option.",
  },
  {
    q: "Does EcoTrove cover both electricity and natural gas?",
    a: "Yes, your EcoTrove payment plan covers both electricity and natural gas. EcoTrove automatically switches your electricity source to a renewable energy generator. Since there is no renewable energy option to power your gas-appliances (gas stove, gas heater etc.), you will continue receiving natural gas without interruption.",
  },
  {
    q: "What is your cancellation policy?",
    a: "You can cancel at any time by emailing us at info@ecotrove.com or on your account, and paying your last due subscription price to EcoTrove. If you decide to cancel due to EcoTrove raising prices of your subscription, then your last month’s utility bill is entirely on us.",
  },
  {
    q: "What is EcoTrove's relationship with PG&E?",
    a: "EcoTrove is a private company, separate from PG&E. EcoTrove is an approved PG&E third-party service provider, with third-party ID 51684. EcoTrove's subscriber agreement includes a tri-party contract between EcoTrove, the EcoTrove customer, and PG&E which allows EcoTrove to act on the customer's behalf and pay bills with PG&E. EcoTrove customers continue to own their PG&E accounts to ensure there is no disruption in service if they decide to stop using EcoTrove, but they no longer pay PG&E bills and paying for PG&E bills becomes EcoTrove's responsibility.",
  },
];

/**
 * Page that displays support questions and FAQs.
 */
export const RDCSupportPage: React.FC = () => {
  const { setErrorAlert } = useAlertStore();
  const [contactSubmitted, setContactSubmitted] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // If the user is signed in, pre-populate their name and email
        const {
          data: { user },
        } = await supabase.auth.getUser();
        if (user?.id) {
          const { data, error } = await supabase
            .from("user-profiles")
            .select("name,email")
            .eq("user_id", user.id)
            .single();
          if (error || !data?.name || !data?.email) {
            throw new Error("an error occurred");
          }
          setName(data.name);
          setEmail(data.email);
        }
      } catch (error) {
        setErrorAlert("An error occurred while fetching user data. Please try again later.");
      }
    };
    mixpanel.track("support");
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tw-preflight">
      <RDCNavbar />
      <div className="flex flex-col items-center mt-navbar">
        <div className="grow p-content-mobile lg:p-content space-y-8 max-w-wide">
          <h1 className="text-2xl">Support</h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="flex flex-col gap-16">
              {/* Contact us */}
              <div className="space-y-4">
                <h2 className="text-xl">Looking for support with EcoTrove?</h2>
                <p className="text-neutral-content">Reach out to our team.</p>
                <button
                  className="daisy-btn daisy-btn-ghost border border-neutral-200 px-button mt-5"
                  onClick={() => Utils.openDialogModal(CONTACT_US_MODAL_ID)}
                  disabled={contactSubmitted}>
                  Contact Us
                </button>
              </div>
              {/* FAQs */}
              <div className="space-y-4">
                <h2 className="text-xl">Common Questions</h2>
                <p className="text-neutral-content">Click on each question to learn more.</p>
                <RDCCollapseList
                  itemsDescription="Support page FAQ questions"
                  items={SUPPORT_FAQS.map(({ q, a }) => {
                    return { text: q, subText: a };
                  })}
                />
              </div>
            </div>
            {/* Art */}
            <img
              className="hidden lg:flex h-full object-cover rounded-3xl"
              src={WindmillArtVertical}
              alt="windmills and house in purple tone"
            />
          </div>
        </div>
        <RDCFooter />
      </div>
      <RDCContactUsModal
        modalId={CONTACT_US_MODAL_ID}
        onSuccess={() => setContactSubmitted(true)}
        name={name}
        email={email}
      />
    </div>
  );
};
