import ReactECharts from "echarts-for-react";
import { BarSeriesOption, EChartsOption, registerTheme } from "echarts";
import { useEffect, useRef, useState } from "react";
import { ECHARTS_BAR_STYLE, ECHARTS_NEUTRAL_CONTENT, ECHARTS_THEME } from "../../constants";
import { Utils } from "../../utils";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

// The duration of each bar chart animation, in MS
const ANIMATION_DURATION_MS = 1500;

/**
 * Props to pass to a `RDCSavingsWaterfall`.
 */
export interface RDCSavingsWaterfallProps {
  monthlyBillBefore: number;
  monthlyBillAfter: number;
  isMUD: boolean;
}

/**
 * Waterfall chart component that shows a customer's savings when switching to an EcoTrove plan.
 * ECharts does not support waterfall charts by default, but we can achieve one by creating a
 * "mask" chart with a transparent color, and stack any floating bars on top of that one.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCSavingsWaterfall: React.FC<RDCSavingsWaterfallProps> = (props) => {
  const { monthlyBillBefore, monthlyBillAfter, isMUD } = props;
  const [options, setOptions] = useState<EChartsOption>();
  const chartRef = useRef<ReactECharts>(null);

  const categoryLabels: { value: string; shortenedValue: string; explainer?: string }[] = [
    {
      value: "Your Bills Next Year with PG&E",
      shortenedValue: "PG&E",
      explainer: isMUD
        ? "Based on your available historical usage and our model forecast of your energy use. PG&E rates have increased and you will be paying higher prices for the same energy use."
        : "If you use the same amount of energy in the next 12 months as you did in the last 12 months, you will pay this amount to your utility over the next year. PG&E rates have increased and you are paying higher prices for the same energy usage.",
    },
    { value: "Your Savings", shortenedValue: "Savings" },
    {
      value: "EcoTrove Max Saver Plan",
      shortenedValue: "EcoTrove",
      explainer: isMUD
        ? "If your usage follows your previous usage and our model forecast, and you allow EcoTrove to optimize your utility account, you will pay this amount over the next year under our Max Saver plan."
        : "If you use the same amount of energy in the next 12 months as you did in the last 12 months, and you allow EcoTrove to optimize your utility account, you will pay this amount to EcoTrove over the next year under our Max Saver plan. If you use less power than we are forecasting, you will receive discounts.",
    },
  ];

  useEffect(() => {
    setOptions(getChartOptions());
    registerTheme("main", ECHARTS_THEME);

    // Resize the chart when the screen resizes
    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.getEchartsInstance().resize();
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Helper method that formats a series label.
   * @param value the value to include in the label
   * @param modifierText the text to display between the yearly and monthly values
   * @returns the label object.
   */
  const formatSeriesLabel = (value: number, modifierText?: string): BarSeriesOption["label"] => {
    let label = `{bold|$${value.toLocaleString()}/yr}`;
    if (modifierText) {
      label += `\n(${modifierText} $${Math.round(value / 12).toLocaleString()}/mo)`;
    }
    return {
      show: true,
      position: "top",
      fontSize: 14,
      align: "center",
      lineHeight: 18,
      formatter: label,
      rich: {
        bold: { fontWeight: "bold", fontSize: 14 },
      },
    };
  };

  /**
   * Helper method that gets the chart config.
   * @returns an `EChartsOption` object.
   */
  const getChartOptions = (): EChartsOption => {
    const billBefore = Math.round(monthlyBillBefore * 12 * 1.045);
    const billAfter = Math.round(monthlyBillAfter * 12);
    const savings = billBefore - billAfter;
    // This y-axis value sets the height of the "savings" bar to roughly 1/4 the total graph height
    const startYAxisAt = Utils.roundToNearestN(billAfter - savings * 3, 100);

    // The amount shown before EcoTrove
    const beforeData = [billBefore, undefined, undefined];
    // The amount shown after EcoTrove
    const afterData = [undefined, undefined, billAfter];
    // The amount saved
    const savingsData = [undefined, savings, undefined];
    // The transparent mask that goes under savingsData
    const maskData = [undefined, billAfter, undefined];

    return {
      grid: {
        left: 0,
        right: 8,
        bottom: 8,
        containLabel: true,
      },
      xAxis: {
        type: "category",
        splitLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        data: categoryLabels.map(({ value }) => {
          return { value };
        }),
      },
      yAxis: {
        type: "value",
        splitLine: { show: false },
        axisLabel: {
          show: true,
          fontSize: 14,
          // Only show the first tick label on the y axis
          formatter: (value: number) => (value === startYAxisAt ? `$${value.toLocaleString()}` : ""),
        },
        min: startYAxisAt,
      },
      animationDuration: ANIMATION_DURATION_MS,
      series: [
        {
          data: maskData,
          type: "bar",
          stack: "all",
          color: "rgba(0,0,0,0)",
          emphasis: { disabled: true },
          markLine: {
            lineStyle: { type: "dashed", color: ECHARTS_NEUTRAL_CONTENT },
            label: { show: false },
            symbol: [],
            emphasis: { disabled: true },
            data: [{ yAxis: maskData[1] }],
            animationDuration: ANIMATION_DURATION_MS,
            animationEasing: "cubicInOut",
            animationDelay: 2 * ANIMATION_DURATION_MS,
          },
        },
        // NOTE: the order of these affects the color that is assigned to the bar (ie. the first one gets primary, second gets secondary, etc.)
        {
          data: savingsData,
          type: "bar",
          itemStyle: ECHARTS_BAR_STYLE,
          stack: "all",
          animationDelay: 3 * ANIMATION_DURATION_MS,
          emphasis: { disabled: true },
          label: formatSeriesLabel(savings),
        },
        {
          data: afterData,
          type: "bar",
          itemStyle: ECHARTS_BAR_STYLE,
          stack: "all",
          animationDelay: ANIMATION_DURATION_MS,
          emphasis: { disabled: true },
          label: formatSeriesLabel(billAfter, "always"),
        },
        {
          data: beforeData,
          type: "bar",
          itemStyle: ECHARTS_BAR_STYLE,
          stack: "all",
          animationDelay: 0,
          emphasis: { disabled: true },
          label: formatSeriesLabel(billBefore, "average"),
        },
      ],
    };
  };

  return options ? (
    <div className="min-h-80">
      <ReactECharts ref={chartRef} theme="main" option={options} />
      <div className="grid grid-cols-3 pl-10">
        {categoryLabels.map(({ value, shortenedValue, explainer }, i) => (
          <div key={i} className="flex justify-self-center items-center px-4 gap-1">
            {/* When the screen is small, show a shortened value to prevent the label text from wrapping */}
            <p className="hidden lg:flex">{value}</p>
            <p className="flex lg:hidden">{shortenedValue}</p>
            {explainer && (
              <div
                className={`daisy-tooltip daisy-tooltip-secondary lg:daisy-tooltip-top ${
                  i === 2 ? "daisy-tooltip-left" : "daisy-tooltip-top"
                } text-secondary`}
                data-tip={explainer}>
                <QuestionMarkCircleIcon className="hero-icon hero-icon-sm" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  ) : (
    // Set a static height when the options haven't loaded yet so the screen doesn't jump around
    <div className="min-h-80"></div>
  );
};
